import { Component, Input } from '@angular/core';
import { ContentTemplate } from '../../../../../../shared';

@Component({
  selector: 'lsb-template-container',
  templateUrl: './template-container.component.html',
  styleUrls: ['./template-container.component.scss'],
})
export class TemplateContainerComponent {
  @Input() templates: ContentTemplate[];
}
