import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Part } from '../../../../../../shared';
import { TranslationHelper } from '../../helpers/translation.helpers';

@Component({
  selector: 'lsb-part-specification',
  templateUrl: './part-specification.component.html',
  styleUrls: ['./part-specification.component.scss'],
})
export class PartSpecificationComponent {
  @Input() part: Part;

  // tslint:disable-next-line: no-output-native
  @Output() select = new EventEmitter<string>();

  constructor(public translations: TranslationHelper) {}

  get partHasBendings(): boolean {
    return this.part != null && this.part.bendingsCount > 0;
  }
}
