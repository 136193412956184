import { Component } from '@angular/core';
import { RadioGroupDirective } from '../../directives/radio-group/radio-group.directive';
import { RadioGroupToken } from '../../directives/radio-group/radio-group.injection-token';
import { RadioGroupProvider } from '../../directives/radio-group/radio-group.provider';

/**
 * Container component to group a number of `RadioButtonComponent`s together.
 *
 * Example
 *
 * ~~~html
 * <lsb-radio-group name="group-1" (selectionChange)="selectedValueIs($event)">
 *    <lsb-radio-button value="A" label="Option A"></lsb-radio-button>
 *    <lsb-radio-button value="B" label="Option B"></lsb-radio-button>
 * </lsb-radio-group>
 * ~~~
 */
@Component({
  selector: 'lsb-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [RadioGroupProvider, { provide: RadioGroupToken, useExisting: RadioGroupComponent }],
})
export class RadioGroupComponent extends RadioGroupDirective {}
