import { Keyword, Part } from '../../../../../shared';
import { DecisionFn, FilterItem, FilterType, SheetThickness } from '../types';

const byCheckedState: DecisionFn<FilterItem> = (filter: FilterItem): boolean => !!filter.active;
const byFilterType: (ft: FilterType) => DecisionFn<FilterItem> = (targetType: FilterType) => (
  filter: FilterItem,
) => filter.type === targetType;

/**
 * Filters a given parts list with the specified filter items.
 * @param parts The parts input list to be filtered.
 * @param filters The filters to be applied on the given list.
 */
export function filterParts(parts: Part[], filters: FilterItem[]): Part[] {
  const materialFilters = filters.filter(byCheckedState).filter(byFilterType(FilterType.Material));
  const requirementFilters = filters
    .filter(byCheckedState)
    .filter(byFilterType(FilterType.Requirement));
  const seamGeometryFilters = filters
    .filter(byCheckedState)
    .filter(byFilterType(FilterType.SeamGeometry));
  const sheetThicknessFilters = filters
    .filter(byCheckedState)
    .filter(byFilterType(FilterType.SheetThickness));

  return parts.filter(matchesAtLeastOneFilterPerFilterType);

  /**
   * Tests if the specified part matches at least one filter of every filter-type
   * that are currently active.
   * @param part The part to be testet against all the active filters.
   * @returns Whether or not the part matches at least one filter per filter-type.
   */
  function matchesAtLeastOneFilterPerFilterType(part: Part): boolean {
    let requirementsMatch = true;
    let seamGeometryMatch = true;
    let materialMatch = true;
    let sheetThicknessMatch = true;

    if (isAvailable(requirementFilters)) {
      requirementsMatch = requirementFilters.some(matchesByRequirement(part));
    }
    if (isAvailable(seamGeometryFilters)) {
      seamGeometryMatch = seamGeometryFilters.some(matchesBySeamGeometry(part));
    }
    if (isAvailable(materialFilters)) {
      materialMatch = materialFilters.some(isMaterialMatch(part));
    }
    if (isAvailable(sheetThicknessFilters)) {
      sheetThicknessMatch = sheetThicknessFilters.some(isSheetThicknessMatch(part));
    }

    return requirementsMatch && seamGeometryMatch && materialMatch && sheetThicknessMatch;
  }

  function isSheetThicknessMatch(part: Part): DecisionFn<FilterItem> {
    return (filter) => {
      return (
        (part.sheetThicknessInMillimeters <= 1 &&
          filter.filter === SheetThickness.LessOrEqualThanOneMillimeter) ||
        (part.sheetThicknessInMillimeters === 1.5 &&
          filter.filter === SheetThickness.EqualToOnePointFiveMillimeter) ||
        (part.sheetThicknessInMillimeters === 2 &&
          filter.filter === SheetThickness.EqualToTwoMillimeter) ||
        (part.sheetThicknessInMillimeters > 2 &&
          filter.filter === SheetThickness.GreaterThanTwoMillimeter)
      );
    };
  }

  function isMaterialMatch(part: Part): DecisionFn<FilterItem> {
    return (filter: FilterItem) => part.material === filter.filter;
  }

  function matchesBySeamGeometry(part: Part): DecisionFn<FilterItem> {
    return (filter: FilterItem) =>
      part.seamGeometry.some((keyword: Keyword) => keyword.displayText === filter.filter);
  }

  function matchesByRequirement(part: Part): DecisionFn<FilterItem> {
    return (filter: FilterItem) =>
      part.requirements.some((requirement: string) => requirement === filter.filter);
  }
}

function isAvailable(filters: FilterItem[]): boolean {
  return filters.length > 0;
}
