import { Media } from './media';
import { Part } from './part';
import { PartInfo } from './part-info';
import { WeldingPrinciple } from './welding-principle';

export interface PartDetails extends Part {
  description: string;
  gallery: Media[];
  relatedParts: PartInfo[];
  cadFileUrl: string;
  weldingPrinciples: WeldingPrinciple[];
  cadFileUrlFixture: string;
  fixtureGallery: Media[];
  relatedFixturePrinciples: WeldingPrinciple[];
}
