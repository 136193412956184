import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import { ItemContainerRefToken } from '../../injection-tokens/item-container-ref.injection-token';
import { ItemContainer, ItemContainerRef } from '../../types';
import { Changes } from '../../types/changes';

/**
 * Drop-down item to be used as child of a `Dropdown` or `Combobox` component.
 *
 * **Example:**
 *
 * ~~~html
 * <lsb-dropdown>
 *  <lsb-dropdown-item value="1" unit="mm"> </lsb-dropdown-item>
 *  <lsb-dropdown-item unit="mm"> {{ 2 }} </lsb-dropdown-item>
 * </lsb-dropdown>
 * ~~~
 *
 * ---
 *
 * **Unit Conversion**
 *
 * **Important:** If no unit is specified, the dropdown item will try to
 * automatically derive its unit from its `ItemContainer`. If you specify
 * the unit, your value will have precedence over the value from the
 * `ItemContainer`.
 */
@Component({
  selector: 'lsb-dropdown-item',
  template: `
    <li class="basic-atom" (click)="select()">
      <span #textContent style="white-space: nowrap">
        <ng-content></ng-content>
      </span>

      <span *ngIf="value !== undefined">{{ value }}</span>
      <span *ngIf="displayUnit !== undefined" style="margin-left: 0.2em">{{ displayUnit }}</span>
    </li>
  `,
})
export class DropdownItemComponent implements OnChanges, OnDestroy {
  @Input() unit: Maybe<string>;
  @Input() value: string;

  @ViewChild('textContent', { read: ElementRef })
  private textContentRef: ElementRef<HTMLSpanElement>;

  public displayUnit?: string;

  public get textContent(): string {
    return this.textContentRef?.nativeElement.textContent ?? '';
  }

  private get itemContainer(): Maybe<ItemContainer> {
    return this.itemContainerRef?.current;
  }

  constructor(
    @Optional() @Inject(ItemContainerRefToken) private itemContainerRef: ItemContainerRef,
  ) {}

  ngOnInit(): void {
    this.updateDisplayUnit();
  }

  ngOnChanges(changes: Changes<this>): void {
    if (changes.unit) {
      this.updateDisplayUnit();
    }
  }

  ngOnDestroy() {
    this.itemContainer?.onItemRemoved(this.textContent);
  }

  public select() {
    this.itemContainer?.select(this.value ?? this.textContent, this.displayUnit);
  }

  private updateDisplayUnit() {
    this.displayUnit = this.unit ?? this.itemContainer?.unit;
  }
}
