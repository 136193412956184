<div class="title" (click)="select.emit(part.id)">{{ part.name }}</div>
<div class="specification-text">{{ part.material }}</div>
<div class="parts-specification">
  <div class="specification-text">
    {{ part.partsCount }} {{ translations.PARTS.SPECIFICATION.PARTS_COUNT | translate }}
  </div>
  <div class="specification-text">
    {{ part.jointsCount }} {{ translations.PARTS.SPECIFICATION.JOINTS_COUNT | translate }}
  </div>
  <div class="specification-text">
    {{ part.bendingsCount }} {{ translations.PARTS.SPECIFICATION.BENDINGS_COUNT | translate }}
  </div>
</div>
