import { ConfiguratorLayoutModel, UsageSide } from '../types';

export const CONFIGURATOR_LAYOUT_MODEL_MOCKS: ConfiguratorLayoutModel[] = [
  {
    name: 'BEO_D70_LINEARDÜSE',
    cadUrl: 'cadUrl',
    imgUrl: 'img.jpg',
    cabin: {
      layout: '4x4',
      size: '4800 x 4800',
    },
    lin: { used: true, side: UsageSide.Left },
    high: { used: false },
    wp: { used: true, side: UsageSide.Right },
    rw: { used: false },
    dt: { used: true },
    npss: { used: true },
    dkp: { used: true, side: UsageSide.Left },
  },
  {
    name: 'TLW5000_4x5',
    cadUrl: 'TLW5000_4x5.STEP',
    imgUrl: 'TLW5000_4x5.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: false },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    name: 'TLW5000_4x5_DKPR',
    cadUrl: 'TLW5000_4x5_DKPR.STEP',
    imgUrl: 'TLW5000_4x5_DKPR.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Right },
    dt: { used: false },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    name: 'TLW5000_4x5_DKPL',
    cadUrl: 'TLW5000_4x5_DKPL.STEP',
    imgUrl: 'TLW5000_4x5_DKPL.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Left },
    dt: { used: false },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 22
    name: 'TLW5000_4x5_DKPR_RWL',
    cadUrl: 'TLW5000_4x5_DKPR_RWL.STEP',
    imgUrl: 'TLW5000_4x5_DKPR_RWL.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Right },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Left },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 23
    name: 'TLW5000_4x5_DKPL_RWR',
    cadUrl: 'TLW5000_4x5_DKPL_RWR.STEP',
    imgUrl: 'TLW5000_4x5_DKPL_RWR.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Left },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Right },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 24
    name: 'TLW5000_4x5_DKPR_DTL',
    cadUrl: 'TLW5000_4x5_DKPR_DTL.STEP',
    imgUrl: 'TLW5000_4x5_DKPR_DTL.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Right },
    dt: { used: true, side: UsageSide.Left },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 25
    name: 'TLW5000_4x5_DKPL_DTR',
    cadUrl: 'TLW5000_4x5_DKPL_DTR.STEP',
    imgUrl: 'TLW5000_4x5_DKPL_DTR.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Left },
    dt: { used: true, side: UsageSide.Right },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 26
    name: 'TLW5000_4x5_RWR',
    cadUrl: 'TLW5000_4x5_RWR.STEP',
    imgUrl: 'TLW5000_4x5_RWR.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Right },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 27
    name: 'TLW5000_4x5_RWL',
    cadUrl: 'TLW5000_4x5_RWL.STEP',
    imgUrl: 'TLW5000_4x5_RWL.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Left },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 28
    name: 'TLW5000_4x5_DTR',
    cadUrl: 'TLW5000_4x5_DTR.STEP',
    imgUrl: 'TLW5000_4x5_DTR.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: true, side: UsageSide.Right },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    // 29
    name: 'TLW5000_4x5_DTL',
    cadUrl: 'TLW5000_4x5_DTL.STEP',
    imgUrl: 'TLW5000_4x5_DTL.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: true, side: UsageSide.Left },
    rw: { used: false },
    wp: { used: false },
    npss: { used: false },
  },
  {
    name: '30',
    cadUrl: '',
    imgUrl: '',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Right },
    dt: { used: false },
    rw: { used: false },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '31',
    cadUrl: '31.STEP',
    imgUrl: '31.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Left },
    dt: { used: false },
    rw: { used: false },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '32',
    cadUrl: '32.STEP',
    imgUrl: '32.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Right },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Left },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '33',
    cadUrl: '33_DKPR_NPSS.STEP',
    imgUrl: '33_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Left },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Right },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '34',
    cadUrl: '34_DKPR_NPSS.STEP',
    imgUrl: '34_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Right },
    dt: { used: true, side: UsageSide.Left },
    rw: { used: false },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '35',
    cadUrl: '35_DKPR_NPSS.STEP',
    imgUrl: '35_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: true, side: UsageSide.Left },
    dt: { used: true, side: UsageSide.Right },
    rw: { used: false },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '36',
    cadUrl: '36_DKPR_NPSS.STEP',
    imgUrl: '36_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Right },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '37',
    cadUrl: '37_DKPR_NPSS.STEP',
    imgUrl: '37_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: false },
    rw: { used: true, side: UsageSide.Left },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '38',
    cadUrl: '38_DKPR_NPSS.STEP',
    imgUrl: '38_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: true, side: UsageSide.Right },
    rw: { used: false },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: '39',
    cadUrl: '39_DKPR_NPSS.STEP',
    imgUrl: '39_DKPR_NPSS.JPG',
    cabin: {
      layout: '4x5',
      size: '4800 x 5900',
    },
    lin: { used: false },
    high: { used: false },
    dkp: { used: false },
    dt: { used: true, side: UsageSide.Left },
    rw: { used: false },
    wp: { used: false },
    npss: { used: true },
  },
  {
    name: 'BEO_D70_LINEARDÜSE',
    cadUrl: 'cadUrl',
    imgUrl: 'img.jpg',
    cabin: {
      layout: '6x5',
      size: '7000 x 5900',
    },
    lin: { used: true, side: UsageSide.Left },
    high: { used: false },
    wp: { used: true, side: UsageSide.Right },
    rw: { used: false },
    dt: { used: true },
    npss: { used: true },
    dkp: { used: true, side: UsageSide.Left },
  },
  {
    name: 'BEO_D70_LINEARDÜSE',
    cadUrl: 'cadUrl',
    imgUrl: 'img.jpg',
    cabin: {
      layout: '7x4',
      size: '8100 x 4800',
    },
    lin: { used: true, side: UsageSide.Left },
    high: { used: false },
    wp: { used: true, side: UsageSide.Right },
    rw: { used: false },
    dt: { used: true },
    npss: { used: true },
    dkp: { used: true, side: UsageSide.Left },
  },
];
