// @ts-nocheck
import { BrowserIdentificationResult } from '../types/browser-identification-result';

// from: https://stackoverflow.com/a/9851769/3063191
export function isSupportedBrowser(result: BrowserIdentificationResult) {
  return result === 'Chrome' || result === 'EdgeChromium' || result === 'Edge';
}

export function identifyBrowser(): BrowserIdentificationResult {
  // Opera 8.0+
  const isOpera =
    (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    // tslint:disable-next-line: only-arrow-functions
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
      // tslint:disable-next-line: no-string-literal
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome
  const isChrome = !!window.chrome && window.navigator?.vendor === "Google Inc.";

  // Edge (based on chromium) detection
  // tslint:disable-next-line: triple-equals
  const isEdgeChromium = !!window.chrome && navigator.userAgent.indexOf('Edg') != -1;

  if (isChrome) {
    return 'Chrome';
  }
  if (isOpera) {
    return 'Opera';
  }
  if (isFirefox) {
    return 'Firefox';
  }
  if (isSafari) {
    return 'Safari';
  }
  if (isIE) {
    return 'InternetExplorer';
  }
  if (isEdge) {
    return 'Edge';
  }
  if (isEdgeChromium) {
    return 'EdgeChromium';
  }
}
