import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MOCK_STL_DATA_URI } from '../../mocks/stl-file';
import { StlModel } from '../../types';
import { CadModelServiceInterface } from './cad-model-service.interface';

@Injectable({ providedIn: 'root' })
export class CadModelMockService implements CadModelServiceInterface {
  convertStepToStl(_: File): Observable<StlModel> {
    return of({ dataUrl: MOCK_STL_DATA_URI, name: 'Mock stl Model' });
  }
}
