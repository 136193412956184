import { Component, Input } from '@angular/core';
import { CaptionLabel } from './types';

/** Renders a list of `CaptionLabelComponent`s. */
@Component({
  selector: 'lsb-caption-label-list',
  templateUrl: './caption-label-list.component.html',
  styleUrls: ['./caption-label-list.component.scss'],
})
export class CaptionLabelListComponent {
  @Input() items: CaptionLabel[] = [];

  public trackByCaption(_: number, item: CaptionLabel): string {
    return item.caption;
  }
}
