import { Pipe, PipeTransform } from '@angular/core';
import { formatText } from '../helpers/text-formatting-helper';

@Pipe({
  name: 'textFormat',
})
export class TextFormatPipe implements PipeTransform {
  transform(value: Maybe<string>): string {
    return formatText(value);
  }
}
