import { Observable, Subject } from 'rxjs';
import { DataUri } from '../file-drop/types';
import { OrbitToolOptions } from './cad-editor-tools/view-tool/view-tool-options';

export type CadEditorStyleGetter = (ctx: CadEditorContext) => StyleDef;

// hint: avoid importing ThreeJS stuff here, since it breaks in jest runtime.
export type ThreeJsRenderer = any;
export type ThreeJsCamera = any;
export type ThreeJsScene = any;

export interface CadEditorToolBase {
  readonly name: string;
  readonly icon: string;
}

export interface CadEditorToolEvent<T = unknown> {
  name: string;
  payload?: T;
}

export interface CadEditorTool<T = unknown> extends CadEditorToolBase {
  events: Subject<CadEditorToolEvent<T>>;
  config(opts: CadEditorToolOpts): void;
  activate(renderer: ThreeJsRenderer, camera: ThreeJsCamera, scene: ThreeJsScene): void;
  deactivate(): void;
  render(): void;
}

export interface CadEditorContext {
  activeToolName: string;
}

export interface StlModel {
  name?: string;
  dataUrl: string;
}

export interface CadApiConversionResult {
  /** The file name of the conversion result. */
  name: string;
  /** The converted data as base64 string in STL-format. */
  data: string;
}

export interface Point {
  x: number;
  y: number;
}

export interface ThreeJsApi {
  toolEvents: Observable<CadEditorToolEvent>;
  modelPreview$: Observable<string | undefined>;
  activeToolName: string;
  defaultTool: CadEditorToolBase;
  configureTools(opts: CadEditorToolOpts): void;
  loadAndAddModel(model: DataUri): Promise<void>;
  attachToAsChild(host: HTMLUnknownElement): void;
  setSize(width: number, height: number): void;
  activateTool(tool: string): void;
  deactivateTools(): void;
  getTools(): CadEditorToolBase[];
  dispose(): void;
  export(): void;
}

/** An object containing keys of CSSStyleDeclaration with custom v */
export type StyleDef = Partial<Record<keyof CSSStyleDeclaration, string>>;

/**
 * Contains all possible options for each available CadEditorTool.
 * All tools will extract the option-properties they need to be
 * correctly configured.
 */
export interface CadEditorToolOpts extends OrbitToolOptions {}
