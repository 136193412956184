import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';

@Component({
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  public readonly uiElementIds = UiElementIds;
  public currentRoute$: Observable<string>;

  constructor(public translations: TranslationHelper, router: Router) {
    // react on navigation end events and starts with the initial router.url:
    this.currentRoute$ = router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
      )
      .pipe(startWith(router.url), map(this.forwardAboutToCopyright));
  }

  private forwardAboutToCopyright(url: string): string {
    return url.endsWith('about') ? `${url}/conditions-of-use` : url;
  }
}
