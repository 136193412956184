import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Article } from '../../../../../../shared/types';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../../shared/services/language/language.service';
import { NewServiceInterface } from './news.service.interface';

const BASE_ROUTE = 'news';

@Injectable()
export class NewsService implements NewServiceInterface {
  constructor(private http: HttpClient, private languageService: LanguageService) {}

  public getArticles(): Observable<Article[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}?lang=${this.languageService.currentLanguage}`;
    return this.http.get<Article[]>(url);
  }
}
