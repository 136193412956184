import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { addListener, removeListener } from 'resize-detector';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ThreeJsViewer, ThreeJsViewerFactory } from '../../tokens';
import { CadEditorToolBase, CadEditorToolEvent, Point, ThreeJsApi } from '../../types';

@Component({
  selector: 'lsb-cad-model-viewer',
  templateUrl: './cad-model-viewer.component.html',
  styleUrls: ['./cad-model-viewer.component.scss'],
  providers: [
    {
      provide: ThreeJsViewer,
      useFactory: (factory: () => ThreeJsApi) => factory(),
      deps: [ThreeJsViewerFactory],
    },
  ],
})
export class CadModelViewerComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  /** A data-url describing the stl-model. */
  @Input() modelDataUri: string;
  /** Whether the user can zoom in and out via mouse scroll. */
  @Input() zoomable?: boolean | string;
  /** Whether the model automatically, infinitely rotates on display. */
  @Input() autoRotate?: boolean | string;
  /** The speed of the model rotation. Only effective when `autoRotate` is activated. */
  @Input() rotationSpeed: number = 1;
  /** Whether the user can rotate the model via mouse-dragging. */
  @Input() rotatable: boolean | string = false;
  /* Whether to show a toolbar allowing the user to interact with the model. */
  @Input() toolbar: boolean | string = false;

  /** Fired whenever a 3D preview image (base64 image) is available for the current model. */
  @Output()
  previewImageChange = new EventEmitter<string | undefined>();
  /** Fired whenever the user clicks the model. Does not fire for model-dragging. */
  @Output() modelClick = new EventEmitter<void>();

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.mouseDown = this.toPoint(event);
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if (!this.mouseDown) {
      return;
    }

    const mouseUp = this.toPoint(event);
    const deltaX = Math.abs(this.mouseDown.x - mouseUp.x);
    const deltaY = Math.abs(this.mouseDown.y - mouseUp.y);
    const hasMoved = [deltaX, deltaY].some((delta) => delta > this.dragThresholdPx);
    this.mouseDown = undefined;

    if (!hasMoved) {
      this.modelClick.emit();
    }
  }

  public editorTools: CadEditorToolBase[] = [];
  public measurement?: Observable<string | null> = of('');
  private mouseDown?: Point;
  private dragThresholdPx = 2;

  private get element() {
    return this.elementRef.nativeElement;
  }

  get allowRotate() {
    return this.rotatable != null && this.rotatable !== false;
  }

  private get allowZoom() {
    return this.zoomable != null && this.zoomable !== false;
  }

  private get allowAutoRotate() {
    return this.autoRotate != null && this.autoRotate !== false;
  }

  private modelPreviewSub: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef<HTMLUnknownElement>,
    @Inject(ThreeJsViewer) public viewer: ThreeJsApi,
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modelDataUriKey: keyof this = 'modelDataUri';
    const toolbarKey: keyof this = 'toolbar';

    if (modelDataUriKey in changes && this.modelDataUri) {
      await this.viewer.loadAndAddModel(this.modelDataUri);
    }

    const controlProps: (keyof this)[] = ['zoomable', 'rotatable', 'autoRotate', 'rotationSpeed'];
    if (controlProps.some((prop) => prop in changes)) {
      this.viewer.configureTools({
        // orbit tool options:
        autoRotate: this.allowAutoRotate,
        autoRotateSpeed: this.rotationSpeed,
        enableRotate: this.allowRotate,
        enableZoom: this.allowZoom,
        // hint: add further options to this type as you add further tools...
      });
    }

    if (toolbarKey in changes) {
      if (this.toolbar !== false) {
        this.viewer.activateTool(this.viewer.defaultTool.name);
      } else {
        this.viewer.deactivateTools();
      }
    }
  }

  ngOnInit(): void {
    this.modelPreviewSub = this.viewer.modelPreview$.subscribe((modelPreview) =>
      this.previewImageChange.emit(modelPreview),
    );
    addListener(this.element, this.onResize);
    this.editorTools = this.viewer.getTools();
  }

  ngAfterViewInit(): void {
    const host = this.element;
    this.viewer.attachToAsChild(host);

    // langju: we need to wait a tick to avoid expression changed after checked error
    requestAnimationFrame(() => this.attachToToolEvents());

    if (this.toolbar !== false) {
      this.viewer.activateTool(this.viewer.defaultTool.name);
    }
  }

  ngOnDestroy(): void {
    removeListener(this.element, this.onResize);
    this.modelPreviewSub?.unsubscribe();
    this.dispose();
  }

  public activateTool(e: Event, toolName: string): void {
    e.stopImmediatePropagation();
    this.viewer.activateTool(toolName);
  }

  public exportAsStl(e: Event) {
    e.stopImmediatePropagation();
    this.viewer.export();
  }

  private attachToToolEvents() {
    this.measurement = this.viewer.toolEvents.pipe(
      filter((e): e is CadEditorToolEvent<string | number> => e.name.startsWith('measure')),
      map((e: CadEditorToolEvent) => {
        if (e.name.endsWith('reset')) {
          return null;
        }

        const length = e.payload as number;
        return length.toFixed(2) + ' mm';
      }),
    );
  }

  private dispose() {
    this.viewer.dispose();
  }

  private toPoint(event: MouseEvent): Point {
    return { x: event.pageX, y: event.pageY };
  }

  private onResize = (): void => {
    const parentWidth = this.element.clientWidth;
    const parentHeight = this.element.clientHeight;
    this.viewer.setSize(parentWidth, parentHeight);
  };
}
