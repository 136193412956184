import { Component, ContentChild, HostListener, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'lsb-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() label = '';
  @Input() flat = false;
  @Input() disabled = false;
  @ContentChild('toggleTemplate', { read: TemplateRef }) toggleTemplate: TemplateRef<any>;

  @HostListener('focusin')
  public onFocusIn() {
    this.hasFocus = true;
  }

  @HostListener('focusout')
  public onFocusOut() {
    this.hasFocus = false;
  }

  public hasFocus = false;

  // tslint:disable-next-line: variable-name
  private _isOpen = false;

  public get isOpen() {
    // Hint: mf: Hide the popup if dropdown is disabled.
    return this._isOpen && !this.disabled;
  }

  public open() {
    this._isOpen = true;
  }

  public toggle() {
    this._isOpen = !this._isOpen;
  }

  public close() {
    this._isOpen = false;
  }
}
