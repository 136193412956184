import { Component, Input } from '@angular/core';

/** A component accepting children that gets decorated by an optional headline and separator line. */
@Component({
  selector: 'lsb-group-container',
  templateUrl: './group-container.component.html',
  styleUrls: ['./group-container.component.scss'],
})
export class GroupContainerComponent {
  /** The text to show as headline above the separator line. */
  @Input() headline = '';
}
