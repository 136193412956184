/*
    IMPORTANT: Please note that the key-value pairs of this enum have a
    semantic meaning.

    They map the language-setting value from TrumpfUser-/Browser-
    language settings to the translation's file-names. This means that this
    enum is responsible for mapping language settings (string) to language
    files (file-names).

    Examples:
    DE = 'de-DE': When language is set to "de"/"DE", the "assets/i18n/de-DE.ts" language file will be used.
    EN_US = 'en-US': When language is set to "en-US"/"EN-US", the "[...]/en-US.ts" language file will be used.
*/

/** Defines the known languages. */
export enum Language {
  DE = 'de-DE',
  DE_DE = 'de-DE',
  EN_US = 'en-US',
  JA_JP = 'ja-JP',
  ES_ES = 'es-ES',
  FR_FR = 'fr-FR',
  IT_IT = 'it-IT',
  ZH_CN = 'zh-CN',
}

// TODO: mf: to be translated
// CS = 'cs-CZ',
// DA = 'da-DK',
// ES = 'es-ES',
// FR = 'fr-FR',
// HU = 'hu-HU',
// ID = 'id-ID',
// IT = 'it-IT',
// JA = 'ja-JP',
// KO = 'ko-KR',
// NL = 'nl-NL',
// PL = 'pl-PL',
// PT = 'pt-BR',
// RU = 'ru-RU',
// SV = 'sv-SE',
// TH = 'th-TH',
// TR = 'tr-TR',
// CHS = 'zh-CHS',
// CHT = 'zh-CHT',
