<div class="container" [class.layered]="modelDataUri" data-ngtx="model-drop-zone:container">
  <lsb-file-drop-zone
    accept=".step,.stp"
    class="drop-zone"
    (fileDrop)="onStepFileDrop($event)"
    (dragleave)="moveModelViewerToFront()"
  >
    <ng-content></ng-content>
  </lsb-file-drop-zone>

  <lsb-cad-model-viewer
    *ngIf="modelDataUri"
    class="model"
    [class.model-at-front]="isModelViewerAtFront"
    [modelDataUri]="modelDataUri"
    [zoomable]="zoomable"
    [rotatable]="rotatable"
    [autoRotate]="autoRotate"
    [rotationSpeed]="rotationSpeed"
    [toolbar]="toolbar"
    (previewImageChange)="previewImageChange.emit($event)"
    (modelClick)="modelClick.emit()"
  ></lsb-cad-model-viewer>
</div>
