<ng-container *ngIf="details$ | async as details">
  <h1 class="title">{{ details.name }}</h1>
  <div class="description">
    {{ details.description }}
  </div>
  <div class="keywords">
    <xui-tag
      class="keyword"
      *ngFor="let keyword of details.keywords"
      (click)="navigateToPrinciple(keyword.id)"
      >{{ keyword.displayText }}</xui-tag
    >
  </div>
  <hr />

  <xui-media-gallery
    class="gallery"
    [items]="details.gallery"
    [(selectedIndex)]="selectedMediaIndex"
  ></xui-media-gallery>

  <hr />

  <lsb-template-container class="templates" [templates]="details.content"></lsb-template-container>

  <ng-container *ngIf="parts$ | async as parts">
    <lsb-list>
      <lsb-list-item *ngFor="let part of parts">
        <lsb-part-list-item
          [part]="part"
          (select)="navigateToPart($event)"
          (keywordSelect)="navigateToPrinciple($event)"
        ></lsb-part-list-item>
      </lsb-list-item>
    </lsb-list>

    <ng-container *ngIf="examples$ | async as examples">
      <xui-button
        *ngIf="examples?.length > examplePartsToShow && parts.length <= examplePartsToShow"
        xuiClickUsageTracking="{{ uiElementIds.WELDING_PRINCIPLES_MORE_DETAILS }}"
        (click)="updateParts$.next()"
        type="secondary"
        class="show-more"
      >
        {{ translations.WELDING_PRINCIPLES.DETAILS.MORE_PARTS | translate }}</xui-button
      >
    </ng-container>
  </ng-container>

  <div class="help-container">
    <h2 class="headline">{{ translations.WELDING_PRINCIPLES.DETAILS.HELP.TITLE | translate }}</h2>
    <div class="text">
      <span>{{ translations.WELDING_PRINCIPLES.DETAILS.HELP.PARAGRAPH_1 | translate }}</span>
      <span>&nbsp;</span>
      <a
        target="_blank"
        [href]="translations.WELDING_PRINCIPLES.DETAILS.HELP.LINK_HOMEPAGE_URL | translate"
      >
        {{ translations.WELDING_PRINCIPLES.DETAILS.HELP.LINK_HOMEPAGE | translate }}
      </a>
      <span>&nbsp;</span>
      <span>{{ translations.WELDING_PRINCIPLES.DETAILS.HELP.PARAGRAPH_2 | translate }}</span>
    </div>
    <a [href]="mailLink">
      <xui-button
        class="send-mail"
        xuiClickUsageTracking="{{ uiElementIds.WELDING_PRINCIPLES_MAIL_TO }}"
        type="trumpf-homepage"
        >E-Mail</xui-button
      >
    </a>
  </div>
</ng-container>
