import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from './types';

/** The `TabsComponent` displays a horizontal list of selectable tabs. */
@Component({
  selector: 'lsb-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'submenu',
  },
})
export class TabsComponent {
  @Input() tabs: Tab[] = [];
  @Input() translateTabs = false;
  @Input() disabled = false;
  @Input() selectedTab: Tab | undefined;
  @Output() selectedTabChange = new EventEmitter<Tab>();

  onTabChange(tab: Tab) {
    if (this.disabled) {
      return;
    }

    this.selectedTab = tab;
    this.selectedTabChange.emit(tab);
  }
}
