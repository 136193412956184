import { Injectable } from '@angular/core';
import { UniqueSelectionService } from '../../services/unique-selection.service';

@Injectable()
export class RadioGroupProvider {
  private knownGroups = new Map<string, UniqueSelectionService>();

  get(groupName: string): UniqueSelectionService {
    if (!this.knownGroups.has(groupName)) {
      this.knownGroups.set(groupName, new UniqueSelectionService());
    }

    const group = this.knownGroups.get(groupName)!;

    return group;
  }
}
