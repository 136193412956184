import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { RefTokenFactory } from '../../factories';
import { ItemContainerRefToken } from '../../injection-tokens/item-container-ref.injection-token';
import { ItemContainer, ItemContainerRef } from '../../types';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { InputFieldComponent } from '../input-field/input-field.component';

/**
 * A simple combobox allowing the user to choose from given options.
 * Options can be passed in as ng-content in form of `lsb-dropdown-item`s.
 *
 * ~~~html
 * <lsb-combobox>
 *    <lsb-dropdown-item *ngFor="let i of items">{{ i }}</lsb-dropdown-item>
 * </lsb-combobox>
 * ~~~
 */
@Component({
  selector: 'lsb-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
  providers: [{ provide: ItemContainerRefToken, useFactory: RefTokenFactory }],
})
export class ComboboxComponent implements ItemContainer {
  @Input() label: string;
  @Input() disabled = false;
  @Input() unit: Maybe<string>;
  @Input() text = '';
  @Input() valueField = false;
  @Output() textChange = new EventEmitter<string>();

  @ViewChild(DropdownComponent) private dropDown: DropdownComponent;
  @ViewChild(InputFieldComponent) private inputField: InputFieldComponent;

  public active = false;
  protected bindablePropertyName: keyof this = 'text';

  constructor(@Inject(ItemContainerRefToken) itemContainerRef: ItemContainerRef) {
    itemContainerRef.current = this;
  }

  public onOutsideClick() {
    this.dropDown.close();
  }

  public onTextChange(text: string) {
    this.textChange.emit(text);
    this.dropDown?.close();
  }

  public select(text: Maybe<string>, unit?: string): void {
    this.text = text ?? '';
    this.textChange.emit(text);
    // this.unit = unit;

    this.dropDown?.close();
  }

  public onItemRemoved(text: string): void {
    if (this.text === text) {
      this.select(undefined);
    }
  }

  protected forceUpdateView(value: string): void {
    setTimeout(() => this.inputField?.forceUpdateView(value), 0);
  }
}
