/** Defines whether and how a component is used within a robot and safety-cabin. */
export type ComponentUsageState = ComponentUsedState | ComponentNotUsedState;

export enum UsageSide {
  Left = 'Left',
  Right = 'Right',
}

export interface ComponentUsedState {
  used: true;
  side?: UsageSide;
}

export interface ComponentNotUsedState {
  used: false;
}
