import { Subject } from 'rxjs';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CadEditorTool, CadEditorToolEvent } from '../../types';
import { setStyle } from '../../utility/set-style';
import { CadToolBase } from '../tool.base';
import { OrbitToolOptions } from './view-tool-options';

export class ViewCadEditorTool extends CadToolBase implements CadEditorTool<void> {
  public readonly events = new Subject<CadEditorToolEvent<void>>();
  public readonly name = 'view';
  public readonly icon = 'V';

  private controls: OrbitControls;
  private mouseDown = false;

  activateTool(): void {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.canvasRef.addEventListener('mousedown', this.onMouseDown);
    this.canvasRef.addEventListener('mouseup', this.onMouseUp);
  }

  deactivateTool(): void {
    this.controls.dispose();
    this.canvasRef.removeEventListener('mousedown', this.onMouseDown);
    this.canvasRef.removeEventListener('mouseup', this.onMouseUp);
  }

  renderTool(): void {
    setStyle({ cursor: this.getCursor() }, this.renderer.domElement);
    this.controls.update();
  }

  applyConfig(options: OrbitToolOptions): void {
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1;
    this.controls.enableZoom = options.enableZoom;
    this.controls.autoRotate = options.autoRotate;
    this.controls.autoRotateSpeed = options.autoRotateSpeed;
    this.controls.enableRotate = options.enableRotate;
  }

  private onMouseDown = () => (this.mouseDown = true);
  private onMouseUp = () => (this.mouseDown = false);

  private getCursor() {
    if (!this.controls.enableRotate) {
      return 'default';
    } else {
      return !this.mouseDown ? 'grab' : 'grabbing';
    }
  }
}
