import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Article } from '../../../../../../shared';
import { ARTICLES_DE_MOCK } from '../../../data/mocks';
import { NewServiceInterface } from './news.service.interface';

@Injectable()
export class NewsServiceMock implements NewServiceInterface {
  public getArticles(): Observable<Article[]> {
    return of(ARTICLES_DE_MOCK);
  }
}
