import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

function valueIsNotNullOrUndefined<T>(value: null | undefined | T): value is T {
  return value !== null && value !== undefined;
}

/**
 * A common RxJS filter pipe to filter null or undefined elements while inferring the correct types.
 * This is especially helpful when using TS strictNullChecks.
 * See: https://medium.com/ngconf/filtering-types-with-correct-type-inference-in-rxjs-f4edf064880d.
 */
export function isNotNullOrUndefined<T>() {
  return (source$: Observable<null | undefined | T>) =>
    source$.pipe(filter(valueIsNotNullOrUndefined));
}
