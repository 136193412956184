import { Pipe, PipeTransform } from '@angular/core';
import { toHumanReadableLanguage } from '../helpers/language-helper';

/**
 * Converts a given language code into its respective human readable value.
 *
 * **Example:**
 *
 * ~~~html
 * <!-- Outputs "English": -->
 * <div> {{ 'en-US' | language }} </div>
 * <!-- Outputs "Deutsch": -->
 * <div> {{ Language.DE_DE | language }} </div>
 * ~~~
 */
@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  transform(value: string): string {
    return toHumanReadableLanguage(value) ?? 'Unknown';
  }
}
