<ng-container *ngIf="checkedFilters$ | async as checkedFilters">
  <div *ngIf="checkedFilters.length > 0" class="filters">
    <xui-tag
      *ngFor="let filterItem of checkedFilters"
      class="tag"
      [removable]="true"
      (remove)="removeFilterKeyword(filterItem)"
    >
      {{ filterItem.filter }}
    </xui-tag>
    <label class="textlink default" (click)="removeAllFilterKeywords()">
      <span class="label">{{ translations.PARTS.CLEAR_ALL_TAGS | translate }}</span>
    </label>
  </div>
</ng-container>

<div class="row">
  <xui-dropdown
    [flat]="true"
    [label]="translations.PARTS.FILTERS.REQUIREMENTS | translate"
    *ngIf="filterableRequirements$ | async as requirements"
  >
    <xui-dropdown-item *ngFor="let filterItem of requirements">
      <xui-checkbox [checked]="filterItem.active" (checkedChange)="toggleFilter(filterItem)">
        {{ filterItem.filter }}
      </xui-checkbox>
    </xui-dropdown-item>
  </xui-dropdown>

  <xui-dropdown
    [flat]="true"
    [label]="translations.PARTS.FILTERS.SEAM_GEOMETRY | translate"
    *ngIf="filterableSeamGeometries$ | async as seamGeometries"
  >
    <xui-dropdown-item *ngFor="let filterItem of seamGeometries">
      <xui-checkbox [checked]="filterItem.active" (checkedChange)="toggleFilter(filterItem)">
        {{ filterItem.filter }}
      </xui-checkbox>
    </xui-dropdown-item>
  </xui-dropdown>

  <xui-dropdown
    [flat]="true"
    [label]="translations.PARTS.FILTERS.MATERIAL | translate"
    *ngIf="filterableMaterials$ | async as materials"
  >
    <xui-dropdown-item *ngFor="let filterItem of materials">
      <xui-checkbox [checked]="filterItem.active" (checkedChange)="toggleFilter(filterItem)">
        {{ filterItem.filter }}
      </xui-checkbox>
    </xui-dropdown-item>
  </xui-dropdown>

  <xui-dropdown
    [flat]="true"
    [label]="translations.PARTS.FILTERS.SHEET_THICKNESS | translate"
    *ngIf="filterableSheetThicknesses$ | async as sheetThicknesses"
  >
    <xui-dropdown-item *ngFor="let filterItem of sheetThicknesses">
      <xui-checkbox [checked]="filterItem.active" (checkedChange)="toggleFilter(filterItem)">
        {{ filterItem.filter }}
      </xui-checkbox>
    </xui-dropdown-item>
  </xui-dropdown>
</div>

<ng-container *ngIf="filteredParts$ | async as parts">
  <lsb-list class="parts" *ngIf="parts.length > 0">
    <lsb-list-item
      *ngFor="let part of parts"
      [flat]="!selectableItems"
      [selected]="part === selectedPart"
      (click)="selectionChange.emit(part)"
    >
      <lsb-part-list-item
        [part]="part"
        [hideKeywords]="hideKeywords"
        (keywordSelect)="openKeyword.emit($event)"
        (select)="openPart.emit($event)"
      ></lsb-part-list-item>
    </lsb-list-item>
  </lsb-list>
</ng-container>
