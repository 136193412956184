import { Injectable, OnDestroy } from '@angular/core';

/** Interface for a selection group manager, to be used with the `UniqueSelectionService`. */
export interface SelectionGroupMember {
  id: string;
  updateSelection: (newState: boolean) => void;
}

/** Service that allows unique single selection across multiple, otherwise unrelated components. */
@Injectable()
export class UniqueSelectionService implements OnDestroy {
  private selectionGroup: SelectionGroupMember[] = [];

  /** Tear-down logic for this service. */
  ngOnDestroy() {
    this.selectionGroup = [];
  }

  /**
   * Selects the component having the specified id.
   * @param id The id of the selection group member that should be selected.
   */
  public select(id: string): void {
    this.selectionGroup.forEach((item) => {
      item.updateSelection(item.id === id);
    });
  }

  /**
   * Adds the specified selection group member to the current list of members in the group.
   * @param member The selection group member to be added to the group.
   */
  public register(member: SelectionGroupMember) {
    this.selectionGroup.push(member);
  }

  /**
   * Removes the specified selection group member from the current list of members in the group.
   * @param id The id of the selection group member, that is supposed to be removed from group.
   */
  public unregister(id: string) {
    this.selectionGroup = this.selectionGroup.filter((item) => item.id !== id);
  }
}
