import { Component, Input } from '@angular/core';
import { Hint } from '../../../../../../../shared';
import { formatText } from '../../../helpers/text-formatting-helper';
import { TranslationHelper } from '../../../helpers/translation.helpers';

/**
 * The hint component receives a {@param hint} of type `Hint` with markup text that is formatted in the view.
 *
 * ```html
 * <lsb-hint [value]="hint"></lsb-hint>
 * ```
 */
@Component({
  selector: 'lsb-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
  @Input() value: Hint | undefined;

  constructor(public translations: TranslationHelper) {}

  public get formattedValue(): string {
    return formatText(this.value?.text);
  }
}
