<div class="wizard">
  <span class="title">{{ translations.UNSUPPORTED_BROWSER.TITLE | translate }}</span>
  <span class="message">{{ translations.UNSUPPORTED_BROWSER.MESSAGE | translate }}</span>
  <xui-button
    class="close-button"
    xuiClickUsageTracking="{{ uiElementIds.UNSUPPORTED_BROWSER_OK }}"
    (click)="close()"
    >OK</xui-button
  >
</div>
