import { Keyword } from './keyword';
import { PartConfigurationDetails } from './part-configuration-details';
import { PartInfo } from './part-info';

export interface Part extends PartInfo, PartConfigurationDetails {
  category: string;
  material: string;
  partsCount: number;
  jointsCount: number;
  bendingsCount: number;
  keywords: Keyword[];
  requirements: string[];
  seamGeometry: Keyword[];
  sheetThicknessInMillimeters: number;
}
