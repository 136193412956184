export interface AppSettings {
  unitSystem: SystemOfUnits;
  currency: Currency;
}

export enum SystemOfUnits {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export enum Currency {
  Euro = 'Euro',
  USDollar = 'USDollar',
}

export type MetricUnit = 'mm' | 'cm';
export type ImperialUnit = 'inch';
export type TimeUnit = 'sec' | 'hours' | 'min';
export type CurrencyUnit = 'eur' | 'eur-cents';
export type CurrencyRateUnit = 'eur/sec' | 'eur/hours' | 'eur/min';
export type ImperialRateUnit = 'inch/min';
export type MetricRateUnit = 'mm/s' | 'm/min';
export type LengthRateUnit = ImperialRateUnit | MetricRateUnit;
export type ValueUnit =
  | MetricUnit
  | ImperialUnit
  | LengthRateUnit
  | TimeUnit
  | CurrencyUnit
  | CurrencyRateUnit;

export interface UnitConverter<T = ValueUnit> {
  from: T;
  to: T;
  convert: (value: number) => number;
  convertBack: (value: number) => number;
}
