import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(imageName: null): null;
  transform(imageName: undefined): undefined;
  transform(imageName: string): string;
  transform(imageName: Maybe<string>): Maybe<string>;
  transform(imageName: Maybe<string> | null): Maybe<string> | null {
    if (imageName === null || imageName === undefined) {
      return imageName;
    }

    let imagePath = imageName;

    if (!imageName.startsWith('data:image') && !imagePath.startsWith('http')) {
      imagePath = `./images/${imageName}`;
    }

    return imagePath;
  }
}
