import { Keyword } from './keyword';
import { Media } from './media';
import { ContentTemplate } from './templates';

/**
 * The WeldingPrincipleDetails is the data type that is used in the frontend.
 * It's translations are already resolved by the backend based on the current
 * language. In the backend e.g. the name is saved as Translation[] with
 * texts for every language. However the frontend receives translated texts.
 */
export interface WeldingPrincipleDetails {
  id: string;
  categoryId: string;
  name: string;
  keywords: Keyword[];
  titleMedia?: Media;
  gallery: Media[];
  description: string;
  content: ContentTemplate[];
  accessibleWithoutLogin: boolean;
}
