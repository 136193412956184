import _cloneDeep from 'lodash.clonedeep';

/**
 * Deeply clones the given value.
 * @param value The value to deep-clone.
 * @returns A cloned value from the one passed.
 */
export function cloneDeep<T>(value: T): T {
  return _cloneDeep(value);
}

export function hasType<T extends keyof Checks>(type: T) {
  return (value: any): value is T => typeOf<T>(value, type as any);
}

export function typeOf<T extends keyof Checks>(val: any, type: 'array'): val is any[];
export function typeOf<T extends keyof Checks>(val: any, type: 'object'): val is object;
export function typeOf<T extends keyof Checks>(
  val: any,
  type: 'function',
): val is (...args: any[]) => any;
export function typeOf<T extends keyof Checks>(val: any, type: 'string'): val is string;
export function typeOf<T extends keyof Checks>(val: any, type: 'number'): val is number;
export function typeOf<T extends keyof Checks>(val: any, type: 'boolean'): val is boolean;
export function typeOf<T extends keyof Checks>(val: any, type: T): val is any {
  const fn = checks[type];

  return fn(val);
}

export function isNullOrUndefined(arg: any): arg is null | undefined {
  return arg === null || arg === undefined;
}

// ---------------------------
// Module Internals
// ---------------------------

const checks = {
  array: (val: any) => Array.isArray(val),
  object: (val: any) => typeof val === 'object',
  function: (val: any): val is Function => typeof val === 'function',
  string: (val: any): val is string => typeof val === 'string',
  number: (val: any): val is number => typeof val === 'number',
  boolean: (val: any): val is boolean => typeof val === 'boolean',
};

type Checks = typeof checks;
