import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileDropZoneComponent } from './components/file-drop-zone/file-drop-zone.component';
import { FileReaderToken } from './tokens';

const DECLARATIONS = [FileDropZoneComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [DECLARATIONS],
  providers: [{ provide: FileReaderToken, useFactory: () => FileReader }],
  exports: [DECLARATIONS],
})
export class FileDropModule {}
