import { Component, Input } from '@angular/core';
import { Illustration } from '../../../../../../../shared';

@Component({
  selector: 'lsb-illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.scss'],
})
export class IllustrationComponent {
  @Input() value: Illustration | undefined;
}
