import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsageTrackingSendImplementationService } from '@trumpf-xguide/xguide';
import {
  NavigationUsageTracking,
  UsageTrackingMessageType,
  UsageTrackingTypes,
} from '../../../../../../../shared';
import { environment } from '../../../../../environments/environment';

const BASE_ROUTE = 'usage-tracking';

/** Service responsible for tracking the user's usage behavior. */
@Injectable({
  providedIn: 'root',
})
export class UsageTrackingService implements UsageTrackingSendImplementationService {
  private readonly secretDomainMappings: Map<string, string>;
  private readonly secretDomains: string[];

  constructor(private http: HttpClient) {
    this.secretDomainMappings = new Map([
      [new URL(environment.oidcIssuer).hostname, 'SECRET_OIDC_URL_HIDDEN'],
    ]);

    this.secretDomains = Array.of(...this.secretDomainMappings.keys());
  }

  public send(
    messageType: UsageTrackingMessageType,
    usageTracking: UsageTrackingTypes,
    anonymousGuid: string,
  ): void {
    // hint: this service class is registered at the xguide UsageTrackingModule and will only be called if usage-tracking is allowed.
    // so there is no allowance check needed at this stage.

    if (UsageTrackingMessageType.Navigation) {
      usageTracking = this.prepareNavigationTrackingContent(usageTracking);
    }

    usageTracking.anonymousGuid = anonymousGuid;
    usageTracking.currentUrl = this.removePotentialSecrets(usageTracking.currentUrl);
    const url = `${environment.apiUrl}/${BASE_ROUTE}/${messageType}`;
    this.http.post<void>(url, usageTracking).toPromise();
  }

  private prepareNavigationTrackingContent(
    usageTracking: NavigationUsageTracking,
  ): NavigationUsageTracking {
    usageTracking.previousUrl = this.removePotentialSecrets(usageTracking.previousUrl);
    return usageTracking;
  }

  private removePotentialSecrets(value: Maybe<string>): Maybe<string> {
    if (value === undefined) {
      return value;
    }

    for (const secretDomain of this.secretDomains) {
      const secretUrlRegex = new RegExp(`http(s)?://${secretDomain}(.)*`);

      return value.replace(
        secretUrlRegex,
        this.secretDomainMappings.get(secretDomain) ?? 'SECRET_DOMAIN_HIDDEN',
      );
    }
  }
}
