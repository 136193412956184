import { Keyword } from '../types';

export const MOCK_KEYWORDS: Keyword[] = [
  {
    id: 'heat-conduction',
    displayText: 'Wärmeleitnaht',
  },
  {
    id: 'fixture',
    displayText: 'Blechvorrichtung',
  },
  {
    id: 'removable-core',
    displayText: 'Entnehmbarer Kern',
  },
];
