/** Defines possible welding methods. */
export type WeldingTechnology = 'arc' | 'laser';

/** Defines all available settings for the calculator. */
export interface CalculatorSettings {
  entryExitTimePerSeamInSec: number;
  hourlyRateLaserInEurPerHour: number;
  hourlyRateArcManualInEurPerHour: number;
  hourlyRateArcRoboterInEurPerHour: number;
}

/** Defines the parameters that have welding-method-agnostic default values. */
interface SharedPartCalculationParameters {
  fixtureCostsInEur: number[];
  programmingTimeInSec: number[];
  setupTimePerBatchInSec: number[];
  tackingTimeInSec: number[];
  loadingTimeInSec: number[];
  measureTimeInSec: number[];
}

/** Defines all parameters that differ depending on the used welding method. */
export interface MethodSpecificPartCalculationParameters {
  weldingSpeedInMillimetersPerSec: number[];
}

/** Defines the whole set of calculation parameters that have a default value. */
export interface DefaultPartCalculationParameters {
  shared: SharedPartCalculationParameters;
  arc: MethodSpecificPartCalculationParameters;
  laser: MethodSpecificPartCalculationParameters;
}

/** Defines all default parameters for a welding method.  */
export interface WeldingMethodDefaultPartCalculationParameters
  extends SharedPartCalculationParameters,
    MethodSpecificPartCalculationParameters {}

export interface PartCalculationResultDetailsPerPart {
  weldingTime: number;
  weldingCosts: number;
  setupCosts: number;
  tackingCosts: number;
  measureCosts: number;
  /** This value holds the **actual** costs, based on whether main-time-parallel loading was enabled or not. */
  loadingCosts: number;
  /**
   * This value holds the hypothetic costs, if main-time-parallel loading was enabled (which could be the case, or not).
   * In contrast to the `loadingCosts`, this value is independent from the value set in the calculation-parameters.
   */
  loadingCostsWithoutMainTimeParallelLoading: number;
  /**
   * This value holds the hypothetic costs, if main-time-parallel loading was disabled (which could be the case, or not).
   * In contrast to the `loadingCosts`, this value is independent from the value set in the calculation-parameters.
   */
  loadingCostsWithMainTimeParallelLoading: number;
  reworkCosts: number;
}

export interface PartCalculationResultDetailsTotal {
  fixtureCosts: number;
  programmingCosts: number;
  totalPieces: number;
  /** Formula: `totalPieces * costsPerPart` */
  totalPiecesCosts: number;
}

/** The result of a part calculation. */
export interface PartCalculationResult {
  detailsPerPartArc: PartCalculationResultDetailsPerPart;
  detailsPerPartLaser: PartCalculationResultDetailsPerPart;
  detailsTotalArc: PartCalculationResultDetailsTotal;
  detailsTotalLaser: PartCalculationResultDetailsTotal;
  costsPerPartArc: number;
  totalCostsArc: number;
  costsPerPartLaser: number;
  totalCostsLaser: number;
}

/** Defines parameters applicable to each welding method. */
export interface WeldingMethodParameters {
  parallelLoading: boolean;
  robotWelding?: boolean;
}

/** Defines parameters that are involved in part calculations. */
export interface PartCalculationParameters {
  /** The welding technology that the user selected. */
  selectedTechnology: WeldingTechnology;
  laser: WeldingMethodParameters;
  arc: WeldingMethodParameters;
}

/** Defines all relevant information for part calculations. */
export interface PartCalculation {
  name: string;
  configuration: PartCalculationConfiguration;
  parameters: PartCalculationParameters;
}

/** Defines the interface of a part-calculation configuration made in the wizard. */
export interface PartCalculationConfiguration {
  basics: CalculationBasics;
  laserWeldingParameters: WeldingParameters;
  arcWeldingParameters: WeldingParameters;
}

/** Defines all basic properties of a part-calculation. */
export interface CalculationBasics {
  totalSeamLength: number;
  amountComponents: number;
  totalPieces: number;
  amountSeams: number;
  batchSize: number;
  /** A data-uri containing the model in STL format. To create it you can use the `CadModelService` and pass in a stl-`File` object. */
  cadModelDataUri?: string;
  /** A base64 image string being the rendered preview of the STL model. */
  cadModelPreview?: string;
}

/** Defines all parameters that are relevant for a part-calculation. */
export interface WeldingParameters {
  fixtureCostsInEur: number;
  programmingTimeInSec: number;
  setupTimePerBatchInSec: number;
  tackingTimeInSec: number;
  loadingTimeInSec: number;
  measureTimeInSec: number;
  weldingSpeedInMillimetersPerSec: number;
  reworkTimeInSec: number;
  advancedSettings?: boolean;
}

/** Represents selected point in PartsCalculationChart. */
export interface PartCalculationChartPoint {
  selectedTechnology: WeldingTechnology;
  selectedPartsCount: number;
}
