import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  Category,
  HMI_USER_HEADER_NAME,
  HMI_USER_HEADER_VALUE,
  WeldingPrinciple,
  WeldingPrincipleDetails,
} from '../../../../../shared';
import { environment } from '../../../environments/environment';
import { HmiService } from '../../shared/services/hmi/hmi.service';
import { LanguageService } from '../../shared/services/language/language.service';
import { CategoryToPrinciples } from '../../types';
import { WeldingPrinciplesServiceInterface } from './welding-principles.service.interface';

const BASE_ROUTE = 'welding-principles';

@Injectable({
  providedIn: 'root',
})
export class WeldingPrinciplesService implements WeldingPrinciplesServiceInterface {
  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private hmiService: HmiService,
  ) {}

  public getCategories(): Observable<Category[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/categories?lang=${this.languageService.currentLanguage}`;
    return this.http.get<Category[]>(url);
  }

  public getCategoryToPrinciples(): Observable<CategoryToPrinciples[]> {
    const getCategoryToPrinciple: (category: Category) => Observable<CategoryToPrinciples> = (
      category,
    ) => this.getPrinciples(category.id).pipe(map((principles) => ({ category, principles })));

    return this.getCategories().pipe(
      switchMap((categories) =>
        forkJoin(categories.map((category) => getCategoryToPrinciple(category))),
      ),
    );
  }

  public getPrinciples(categoryId: string): Observable<WeldingPrinciple[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/principles/${categoryId}?lang=${this.languageService.currentLanguage}`;
    return this.http.get<WeldingPrinciple[]>(url);
  }

  public getPrincipleDetails(id: string): Observable<WeldingPrincipleDetails> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/principle-details/${id}?lang=${this.languageService.currentLanguage}`;

    let httpOptions = {};
    if (this.hmiService.hideLogin) {
      // Because in HMI mode user is always unauthorized, there is a hack for getting principle details.
      httpOptions = {
        headers: new HttpHeaders({
          [HMI_USER_HEADER_NAME]: HMI_USER_HEADER_VALUE,
        }),
      };
    }
    return this.http.get<WeldingPrincipleDetails>(url, httpOptions);
  }
}
