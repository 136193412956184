import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { codeFlowAuthConfig } from '../../../config/code-flow-auth-config';
import { UserProfile } from '../../../types';
import { AuthServiceInterface } from './auth.service.interface';

/**
 * Inspired by: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards
 */

@Injectable()
export class AuthService implements AuthServiceInterface {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(this.hasValidToken());
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(true);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private userProfileSubject$ = new BehaviorSubject<Maybe<UserProfile>>(undefined);
  public userProfile$ = this.userProfileSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map(([isAuthenticated, isDoneLoading]) => isAuthenticated && isDoneLoading));

  constructor(private oauthService: OAuthService, private router: Router) {
    this.oauthService.configure(codeFlowAuthConfig);

    if (!environment.production) {
      // Useful for debugging:
      this.oauthService.events.subscribe((event) => {
        if (event instanceof OAuthErrorEvent) {
          console.error(event);
        } else {
          console.warn(event);
        }
      });
    }

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated',
      );
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      }
    });

    this.oauthService.events.subscribe(() => {
      const hasValidToken = this.oauthService.hasValidAccessToken();
      if (this.isAuthenticatedSubject$.value !== hasValidToken) {
        this.isAuthenticatedSubject$.next(hasValidToken);
      }
    });

    this.oauthService.events
      .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(() => this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken()));

    // This *does* work with v9.0.0 as it detects code+pkce flow and sets up
    // refreshToken() calls that require offline_access, instead of actually
    // calling silentRefresh, which would fail because of this issue:
    // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/600
    this.oauthService.setupAutomaticSilentRefresh();

    this.isAuthenticatedSubject$
      .pipe(switchMap(() => this.loadUserProfile()))
      .subscribe((userProfile) => {
        this.userProfileSubject$.next(userProfile);
      });
  }

  public async runInitialLoginSequence(): Promise<void> {
    // LOAD CONFIG:
    // First we have to check to see how the OIDC Server is currently configured
    await this.oauthService.loadDiscoveryDocument();

    // Check and process the login parameter after a redirect from a OIDC Server
    await this.oauthService.tryLogin();

    if (!this.hasValidToken()) {
      // Try to refresh the oauth token with a refresh token from a previuous login
      await this.startWithRefresh().catch((refreshError) => {
        console.error('Error during refresh of token: ', refreshError);
      });
    }

    let navigateUrl = window.location.hash.replace('#', '');

    const oAuthSavedState = this.oauthService.state;
    if (oAuthSavedState !== undefined && oAuthSavedState !== '') {
      // Since we send the user with a current state to the login page, we have
      // to load the last state again
      navigateUrl = decodeURIComponent(oAuthSavedState);
    }

    // We need to manually route, since we prevented the initial routing
    this.router.navigateByUrl(navigateUrl);

    this.isDoneLoadingSubject$.next(true);
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public loadUserProfile(): Observable<Maybe<UserProfile>> {
    if (!this.oauthService.hasValidAccessToken()) {
      return of(undefined);
    }

    console.log('User Token Claims: ', this.oauthService.getIdentityClaims());

    const tokenClaim = this.oauthService.getIdentityClaims() as UserInfo;

    const confirmedCustomer =
      tokenClaim.customerNumber !== undefined &&
      tokenClaim.customerNumber !== null &&
      tokenClaim.customerNumber !== '';

    const userProfile: UserProfile = {
      familyName: tokenClaim.family_name,
      givenName: tokenClaim.given_name,
      locality: tokenClaim.locality,
      confirmedCustomer,
    };

    console.log('Transformed User Profile: ', userProfile);

    return of(userProfile);
  }

  public logout() {
    this.oauthService.logOut();
  }

  public refresh() {
    // Silent refresh via iframe is not supported (yet?) for the code+pkce flow.
    // See also: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/600
    // this.oauthService.silentRefresh();
    // So for now we do this instead:
    this.oauthService.refreshToken();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  private startWithRefresh() {
    if (this.oauthService.getRefreshToken()) {
      return this.oauthService.refreshToken();
    }

    // No silent refresh via iframe is supported for code flow yet.
    // See also: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/600
    return Promise.reject();
  }
}
