<xui-sub-menu>
  <xui-sub-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.PARTS_ALL }}"
    [selected]="(selectedPartCategory$ | async) === undefined"
    [label]="translations.PARTS.MENU.ALL | translate"
    (click)="deselectPartCategory()"
  ></xui-sub-menu-tab>
  <xui-sub-menu-tab
    *ngFor="let category of partCategories$ | async"
    xuiClickUsageTracking="{{ uiElementIds.PARTS_CATEGORY_ALL }}"
    [specificElementIdentifier]="category"
    [label]="category"
    [selected]="category === (selectedPartCategory$ | async)"
    (click)="selectPartCategory(category)"
  ></xui-sub-menu-tab>
</xui-sub-menu>

<div class="content">
  <lsb-part-filter-list
    [parts]="parts$ | async"
    (openPart)="navigateToPartDetails($event)"
    (openKeyword)="navigateToWeldingPrinciple($event)"
  ></lsb-part-filter-list>
</div>
