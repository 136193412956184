import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[lsbClickOutside]',
})
export class ClickOutsideDirective {
  @Output() lsbClickOutside = new EventEmitter();

  constructor(private element: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (targetElement) {
      const clickedInside = this.element.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.lsbClickOutside.emit(event);
      }
    }
  }
}
