<ng-container
  *ngFor="let tab of tabs; let index = index"
  [ngTemplateOutlet]="tabItem"
  [ngTemplateOutletContext]="{ $implicit: tab }"
></ng-container>

<!-- Need to declare tab inline, to not break styleguide -->
<ng-template let-tab #tabItem>
  <!-- ATOM: Tab -->
  <div
    class="tab-submenu"
    (click)="onTabChange(tab)"
    [class.disabled]="disabled"
    [class.selected]="tab === selectedTab"
  >
    <div class="content">
      <span>
        <ng-container *ngIf="translateTabs; else pureText">
          {{ tab.label | translate }}
        </ng-container>

        <ng-template #pureText>
          {{ tab.label }}
        </ng-template>
      </span>
    </div>
  </div>
</ng-template>
