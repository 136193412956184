<div data-ngtx="content-container" data-role="content">
  <ng-content></ng-content>
</div>

<input
  #fileInput
  type="file"
  [attr.multiple]="allowMultiple"
  [attr.accept]="accept"
  (change)="onFileChange($event)"
/>
