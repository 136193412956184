import { Language } from '../../../../../shared';

/**
 * Converts a given language code into its respective human readable value.
 *
 * **Example:**
 *
 * ~~~ts
 * toHumanReadableLanguage(Language.DE_DE); // => 'Deutsch'
 * toHumanReadableLanguage('en-US'); // => 'English'
 * ~~~
 * @param languageCode The language string to convert into a human readable string.
 */
export function toHumanReadableLanguage(languageCode: string | Language): Maybe<string> {
  let asHumanReadable: string = 'Unknown';

  const code = languageCode.toLowerCase();

  if (code.startsWith('de')) {
    asHumanReadable = 'Deutsch';
  } else if (code.startsWith('en')) {
    asHumanReadable = 'English';
  } else if (code.startsWith('jp') || code.startsWith('ja')) {
    asHumanReadable = '日本語';
  } else if (code.startsWith('es')) {
    asHumanReadable = 'Español';
  } else if (code.startsWith('fr')) {
    asHumanReadable = 'Français';
  } else if (code.startsWith('it')) {
    asHumanReadable = 'Italiano';
  } else if (code.startsWith('zh')) {
    asHumanReadable = '中国';
  }

  return asHumanReadable;
}
