<div class="option-block" [class.dark]="dark">
  <div
    class="option-caption"
    [@highlightText]="{ value: highlighted, params: { duration: highlightDuration } }"
    (@highlightText.done)="resetHighlight()"
  >
    <ng-container *ngIf="translateCaption; else rawCaption">
      {{ item?.caption! | translate }}
    </ng-container>
  </div>
  <div
    [@highlightLine]="{ value: highlighted, params: { duration: highlightDuration } }"
    class="hr-line"
  ></div>
  <div
    class="option-label"
    [@highlightText]="{ value: highlighted, params: { duration: highlightDuration } }"
  >
    <ng-container *ngIf="translateLabel; else rawLabel">
      {{ item?.label | translate }}
    </ng-container>

    <ng-content></ng-content>
  </div>

  <ng-template #rawCaption>
    {{ item?.caption }}
  </ng-template>
  <ng-template #rawLabel>
    {{ item?.label }}
  </ng-template>
</div>
