import { onlyUnique } from '../../shared/helpers';

export class EnumInterop<T extends object> {
  constructor(private enumeration: T) {}

  public toStrings(): string[] {
    return Object.values(this.enumeration).filter(onlyUnique);
  }

  public toEnumValue(input: string): T[keyof T] {
    return (this.enumeration as any)[input];
  }
}
