import { Component } from '@angular/core';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';

@Component({
  selector: 'lsb-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent {
  constructor(public translations: TranslationHelper) {}
}
