import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Part, PartDetails, PartFilter } from '../../../../../../shared';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { PartsServiceInterface } from './parts.service.interface';

const BASE_ROUTE = 'parts';

@Injectable({
  providedIn: 'root',
})
export class PartsService implements PartsServiceInterface {
  constructor(private http: HttpClient, private languageService: LanguageService) {}

  getAll(): Observable<Part[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}?lang=${this.languageService.currentLanguage}`;
    return this.http.get<Part[]>(url);
  }

  getPartsAvailableForCalculation(): Observable<Part[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}?lang=${this.languageService.currentLanguage}&filter=${PartFilter.AVAILABLE_FOR_CALCULATION}`;
    return this.http.get<Part[]>(url);
  }

  getAllPartDetails(): Observable<PartDetails[]> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/details?lang=${this.languageService.currentLanguage}`;
    return this.http.get<PartDetails[]>(url);
  }

  getPartDetails(id: string): Observable<PartDetails> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/${id}?lang=${this.languageService.currentLanguage}`;
    return this.http.get<PartDetails>(url);
  }
}
