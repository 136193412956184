<div class="output-text h1">
  <span class="output-headline">{{ translations.COPYRIGHT.TITLE | translate }}</span>
</div>

<div class="output copytext content">
  <span class="output-content">
    {{ translations.COPYRIGHT.PARAGRAPH1 | translate }}
  </span>
  <span class="output-content">
    {{ translations.COPYRIGHT.PARAGRAPH2 | translate }}
  </span>
</div>
