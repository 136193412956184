import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { RadioGroupToken } from './radio-group.injection-token';
import { RadioGroupProvider } from './radio-group.provider';
import { RadioGroup } from './types';

/**
 * Directive to bind `RadioButtonComponent`s together that do not fit into a single `RadioGroupComponent`.
 *
 * ---
 * **Please Note:** Prefer using the `RadioGroupComponent` whenever you can. Only use the directive
 * if you have good reasons for it, since it is the less readable way of binding radio buttons togehter.
 * ---
 * **Example**
 *
 * Cannot use `<lsb-radio-group>` within table when the radio buttons are distributed over several
 * table cells:
 *
 * ~~~html
 * <table>
 * <tr>
 *  <td>
 *    <lsb-radio-button lsbRadioGroup="group1" value="A" label="A"></lsb-radio-button>
 *  </td>
 *  <td>
 *    <lsb-radio-button lsbRadioGroup="group1" value="B" label="B"></lsb-radio-button>
 *  </td>
 * </tr>
 * </table>
 * ~~~
 *
 */
@Directive({
  selector: '[lsbRadioGroup]',
  providers: [{ provide: RadioGroupToken, useExisting: RadioGroupDirective }],
})
export class RadioGroupDirective implements RadioGroup {
  @Input('lsbRadioGroup') name: string;
  @Output() selectionChange = new EventEmitter<any>();

  constructor(private groups: RadioGroupProvider) {}

  ngOnInit() {
    if (!this.name) {
      this.name = uuid();
    }
  }

  public register(id: string, updateSelection: (newState: boolean) => void) {
    const group = this.groups.get(this.name);
    group.register({ id, updateSelection });
  }

  public select(id: string, value: any): void {
    const group = this.groups.get(this.name);
    this.selectionChange.emit(value);
    group.select(id);
  }
}
