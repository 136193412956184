import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Part } from '../../../../../../shared';
import { filterParts, TranslationHelper } from '../../helpers';
import { FilterItem, FilterType, SheetThickness } from '../../types';

@Component({
  selector: 'lsb-part-filter-list',
  templateUrl: './part-filter-list.component.html',
  styleUrls: ['./part-filter-list.component.scss'],
})
export class PartFilterListComponent implements OnInit {
  @Input() parts: Part[] = [];
  @Input() hideKeywords = false;
  @Input() selectableItems = false;
  @Input() selectedPart?: Part;

  @Output() openPart = new EventEmitter<string>();
  @Output() openKeyword = new EventEmitter<string>();

  @Output() selectionChange = new EventEmitter<Part>();
  @Output() filterChange = new EventEmitter<FilterItem[]>();

  private parts$: Observable<Part[]> = of([]);
  private checkedFilters = new BehaviorSubject<FilterItem[]>([]);

  public checkedFilters$: Observable<FilterItem[]> = this.checkedFilters.asObservable();
  public filterableRequirements$: Observable<FilterItem[]>;
  public filterableSeamGeometries$: Observable<FilterItem[]>;
  public filterableMaterials$: Observable<FilterItem[]>;
  public filterableSheetThicknesses$: Observable<FilterItem[]>;
  public filteredParts$: Observable<Part[]>;
  public subscriptions: Subscription;

  constructor(public translations: TranslationHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    const selectableKey: keyof this = 'selectableItems';
    const partsKey: keyof this = 'parts';

    if (changes[selectableKey as string] && !this.selectableItems) {
      this.selectedPart = undefined;
    }

    if (changes[partsKey as string]) {
      this.parts$ = of(this.parts ?? []);
      this.initParts();
    }
  }

  ngOnInit(): void {
    this.initParts();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromParts();
  }

  private initParts() {
    this.unsubscribeFromParts();
    this.subscriptions = new Subscription();

    this.filteredParts$ = combineLatest([this.parts$, this.checkedFilters$]).pipe(
      map(([parts, checkedFilters]) => filterParts(parts, checkedFilters)),
    );

    this.filterableRequirements$ = this.parts$.pipe(
      map((parts) =>
        this.extractUniqueRequirementsFromParts(parts).map((requirement) =>
          this.createFilterItem(requirement, FilterType.Requirement),
        ),
      ),
    );

    this.filterableSeamGeometries$ = this.parts$.pipe(
      map((parts) =>
        this.extractUniqueSeamGeometriesFromParts(parts).map((seamGeometry) =>
          this.createFilterItem(seamGeometry, FilterType.SeamGeometry),
        ),
      ),
    );

    this.filterableMaterials$ = this.parts$.pipe(
      // Use a set to filter duplicates.
      map((parts) => Array.from(new Set(parts.map((part) => part.material)))),
      map((materials) =>
        materials.map((material) => this.createFilterItem(material, FilterType.Material)),
      ),
    );

    this.filterableSheetThicknesses$ = this.parts$.pipe(
      map((parts) =>
        this.extractUniqueSheetThicknessesFromParts(parts).map((part) =>
          this.createFilterItem(part, FilterType.SheetThickness),
        ),
      ),
    );

    this.subscriptions.add(
      this.checkedFilters$.subscribe((filters) => this.filterChange.emit(filters)),
    );
  }

  private unsubscribeFromParts() {
    this.subscriptions?.unsubscribe();
  }

  /**
   * Toggles the given filter item to be active or inactive, based on its current state.
   * @param filterItem The filter item to toggle active/inactive.
   */
  public toggleFilter(filterItem: FilterItem) {
    filterItem.active = !filterItem.active;
    const checkedFilters = this.checkedFilters.getValue();

    if (filterItem.active) {
      checkedFilters.push(filterItem);
    } else {
      this.removeFilterKeyword(filterItem);
      return;
    }

    this.checkedFilters.next(checkedFilters);
  }

  /** Removes the specified filter from the filter list. */
  public removeFilterKeyword(filterItem: FilterItem) {
    filterItem.active = false;
    const checkedFilters = this.checkedFilters.getValue();

    const index = checkedFilters.indexOf(filterItem);

    if (index > -1) {
      checkedFilters.splice(index, 1);
      this.checkedFilters.next(checkedFilters);
    }
  }

  /** Removes all filters from the filters list. */
  public removeAllFilterKeywords() {
    const checkedFilters = this.checkedFilters.getValue();
    checkedFilters.forEach((filterItem) => {
      filterItem.active = false;
    });

    this.checkedFilters.next([]);
  }

  private createFilterItem<T>(filter: T, type: FilterType): FilterItem<T> {
    return {
      filter,
      active: false,
      type,
    };
  }

  private extractUniqueRequirementsFromParts(parts: Part[]) {
    return parts.reduce(
      (prev, curr) => [
        ...prev,
        ...curr.requirements.filter(
          (newRequirement) =>
            !prev.some((existingRequirement) => existingRequirement === newRequirement),
        ),
      ],
      [] as string[],
    );
  }

  private extractUniqueSeamGeometriesFromParts(parts: Part[]) {
    return parts.reduce(
      (prev, curr) => [
        ...prev,
        ...curr.seamGeometry
          .filter(
            (newSeamGeometry) =>
              !prev.some(
                (existingSeamGeometry) => existingSeamGeometry === newSeamGeometry.displayText,
              ),
          )
          .map((seamGeometry) => seamGeometry.displayText),
      ],
      [] as string[],
    );
  }

  private extractUniqueSheetThicknessesFromParts(parts: Part[]) {
    const thicknesses = new Set<SheetThickness>();
    for (const part of parts) {
      if (part.sheetThicknessInMillimeters <= 1) {
        thicknesses.add(SheetThickness.LessOrEqualThanOneMillimeter);
      } else if (part.sheetThicknessInMillimeters === 1.5) {
        thicknesses.add(SheetThickness.EqualToOnePointFiveMillimeter);
      } else if (part.sheetThicknessInMillimeters === 2) {
        thicknesses.add(SheetThickness.EqualToTwoMillimeter);
      } else if (part.sheetThicknessInMillimeters > 2) {
        thicknesses.add(SheetThickness.GreaterThanTwoMillimeter);
      }
    }

    return Array.from(thicknesses);
  }
}
