<section *ngIf="toolbar !== false" class="toolbar">
  <div
    *ngFor="let tool of editorTools"
    class="tool"
    [class.active]="viewer.activeToolName === tool.name"
    (mouseup)="activateTool($event, tool.name)"
  >
    {{ tool.icon }}
  </div>
  <div class="tool" (mouseup)="exportAsStl($event)" title="export as stl">Ex</div>
</section>

<section *ngIf="measurement | async as measurement" class="measurement-info">
  {{ measurement }}
</section>
