// Typing helper for translation files from https://medium.com/better-programming/angular-ngx-translate-typings-816eb4b5d92

import { Injectable } from '@angular/core';
import lang from '../../../assets/i18n/lang';

export function GenericClass<Props>(): new () => Props {
  return class {} as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

function transformObjectToPath<T extends object | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = '',
): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce(
        (objectToTransform: any, [key, value]) => {
          objectToTransform[key] = transformObjectToPath(
            key,
            value,
            concatIfExistsPath(path, suffix),
          );

          return objectToTransform;
        },
        {} as T,
      )
    : (concatIfExistsPath(path, suffix) as T);
}

@Injectable()
export class TranslationHelper extends GenericClass<typeof lang>() {
  constructor() {
    super();
    Object.assign(this, transformObjectToPath('', lang));
  }
}
