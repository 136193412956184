import { Component } from '@angular/core';
import { SettingsAccessor, SettingsService } from '@trumpf-xguide/xguide';
import { Language } from '../../../../../shared';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../shared/constants';
import { toHumanReadableLanguage } from '../../shared/helpers/language-helper';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { LanguageService } from '../../shared/services/language/language.service';
import { AppSettings, Currency, SystemOfUnits } from '../../shared/types';
import { EnumInterop } from './enum-interop';

@Component({
  selector: 'lsb-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  private units = new EnumInterop(SystemOfUnits);
  private currencies = new EnumInterop(Currency);
  private languages = new EnumInterop(Language);
  private settings: SettingsAccessor<AppSettings>;

  public availableUnits = this.units.toStrings();
  public availableCurrencies = this.currencies.toStrings();
  public availableLanguages = this.languages.toStrings();

  public selectedUnit: SystemOfUnits;
  public selectedCurrency: Currency;
  public get selectedLanguage() {
    return toHumanReadableLanguage(this.languageService.currentLanguage) ?? '';
  }

  constructor(
    public translations: TranslationHelper,
    private languageService: LanguageService,
    private settingsService: SettingsService,
  ) {
    this.settings = this.settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );
  }

  ngOnInit() {
    this.selectedUnit = this.settings.get('unitSystem');
    this.selectedCurrency = this.settings.get('currency');
  }

  public changeLanguage(language: string): void {
    this.languageService.switchLanguage(language);
  }

  public changeUnit(unit: string): void {
    const newUnit = this.units.toEnumValue(unit);
    this.settings.update({ unitSystem: newUnit });
    this.selectedUnit = newUnit;
  }

  public changeCurrency(currency: string): void {
    const newCurrency = this.currencies.toEnumValue(currency);
    this.settings.update({ currency: newCurrency });
    this.selectedCurrency = newCurrency;
  }
}
