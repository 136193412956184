import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WeldingPrinciple } from '../../../../../shared';
import { WeldingPrinciplesService } from '../../services/welding-principles/welding-principles.service';
import { isNotNullOrUndefined } from '../../shared/helpers/rxjs.helpers';

@Component({
  selector: 'lsb-welding-principle-overview',
  templateUrl: './welding-principle-overview.component.html',
  styleUrls: ['./welding-principle-overview.component.scss'],
})
export class WeldingPrincipleOverviewComponent implements OnInit {
  public principles$: Observable<WeldingPrinciple[]> | undefined;

  constructor(private route: ActivatedRoute, private service: WeldingPrinciplesService) {}

  ngOnInit() {
    this.principles$ = this.route.paramMap.pipe(
      map((params) => params.get('categoryId')),
      isNotNullOrUndefined(),
      switchMap((id) => this.service.getPrinciples(id)),
    );
  }
}
