import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CaptionLabel, CaptionLabelTranslateMode } from '../types';

/** Renders a label consisting of a "caption"-text being above a separator line and a "label"-text being below this separator. */
@Component({
  selector: 'lsb-caption-label',
  templateUrl: './caption-label.component.html',
  styleUrls: ['./caption-label.component.scss'],
  animations: [
    trigger('highlightText', [
      state('false', style({ color: 'inherit' })),
      state('true', style({ color: '#177ac9' })),
      transition('false => true', animate('0.3s')),
      transition('true => false', animate('0.3s {{ duration }}')),
    ]),
    trigger('highlightLine', [
      state('false', style({})),
      state('true', style({ outlineColor: '#177ac9' })),
      transition('false => true', animate('0.3s')),
      transition('true => false', animate('0.3s {{ duration }}')),
    ]),
  ],
})
export class CaptionLabelComponent {
  @Input() item?: CaptionLabel;
  @Input() dark = false;
  @Input() highlightOnUpdate = false;
  @Input() highlightDuration = '0.3s';

  public highlighted: 'true' | 'false' = 'false';

  public get translateCaption() {
    return (['all', 'caption'] as Maybe<CaptionLabelTranslateMode>[]).includes(
      this.item?.translate,
    );
  }

  public get translateLabel() {
    return (['all', 'label'] as Maybe<CaptionLabelTranslateMode>[]).includes(this.item?.translate);
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldBlink = changes.item && this.highlightOnUpdate;
    this.highlighted = shouldBlink ? 'true' : 'false';
  }

  public resetHighlight() {
    requestAnimationFrame(() => (this.highlighted = 'false'));
  }
}
