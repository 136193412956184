import { getType as getMimeType } from 'mime';

/**
 * Checks whether a given file has one of the specified file-extensions.
 *
 * ---
 * **Example:**
 * ~~~ts
 * const file: File = ...;
 * if(!hasFileType('.pdf, video/mpeg, audio/*', file)) {
 *   return;
 * }
 * ~~~
 * ---
 * @param acceptString A string of comma separated file extensions (including dots).
 * @param file The file object to check for its file-extension.
 * @returns Whether the file has the wanted file-extension.
 */
export function hasFileType(acceptString: string | undefined, file: File): boolean {
  if (acceptString == null) {
    return true;
  }
  if (!file?.name) {
    return false;
  }
  if (!file.name.includes('.')) {
    return false;
  }

  const fileName = file.name.toLocaleLowerCase().trim();

  return acceptString
    .split(',')
    .map((allowedTypes) => allowedTypes.trim())
    .some((allowedExtensionsOrMimeType) => {
      const hasAllowedFileExt = fileName.endsWith(allowedExtensionsOrMimeType);
      const hasAllowedMimeType = areMimeTypesEqual(
        allowedExtensionsOrMimeType,
        getMimeType(fileName),
      );

      return hasAllowedFileExt || hasAllowedMimeType;
    });
}

// ---------------------------------------------------
// Module internals
// ---------------------------------------------------

function areMimeTypesEqual(mimeTypeA: string, mimeTypeB: string | null): boolean {
  const lowerA = mimeTypeA.toLocaleLowerCase();
  const lowerB = mimeTypeB?.toLocaleLowerCase() ?? 'unknown';
  const wildCard = '/*';

  if (lowerA.includes(wildCard)) {
    return compareWildCard(lowerA, lowerB);
  }
  if (lowerB.includes(wildCard)) {
    return compareWildCard(lowerB, lowerA);
  }

  return lowerA === lowerB;
}

function compareWildCard(mimeWithWildCard: string, other: string): boolean {
  const withoutWildCard = mimeWithWildCard.replace('/*', '');
  return other.startsWith(withoutWildCard);
}
