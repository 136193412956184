import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MOCK_PARTS, MOCK_PART_DETAILS, Part, PartDetails } from '../../../../../../shared';
import { PartsServiceInterface } from './parts.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PartsServiceMock implements PartsServiceInterface {
  getAll(): Observable<Part[]> {
    console.log('Fetch parts.');
    return of(MOCK_PARTS);
  }

  getAllPartDetails(): Observable<PartDetails[]> {
    console.log('Fetch all parts details.');
    return of([MOCK_PART_DETAILS, MOCK_PART_DETAILS, MOCK_PART_DETAILS, MOCK_PART_DETAILS]);
  }

  getPartsAvailableForCalculation(): Observable<Part[]> {
    const partsAvailableForCalc = MOCK_PARTS.filter((p) => p.availableForCalculation === true);
    return of(partsAvailableForCalc);
  }

  getPartDetails(id: string): Observable<PartDetails> {
    console.log('Fetch part details', id);
    return of(MOCK_PART_DETAILS);
  }
}
