import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { hasFileType } from '../../../file-drop/utility/has-file-type';
import { CadModelService } from '../../services';

@Component({
  selector: 'lsb-cad-model-drop-zone',
  templateUrl: './cad-model-drop-zone.component.html',
  styleUrls: ['./cad-model-drop-zone.component.scss'],
})
export class CadModelDropZoneComponent implements OnInit {
  /** A data-url describing the stl-model. */
  @Input() modelDataUri?: string = undefined;
  /** Whether the user can zoom in and out via mouse scroll. */
  @Input() zoomable?: boolean | string;
  /** Whether the model automatically, infinitely rotates on display. */
  @Input() autoRotate?: boolean | string;
  /** The speed of the model rotation. Only effective when `autoRotate` is activated. */
  @Input() rotationSpeed: number = 1;
  /** Whether the user can rotate the model via mouse-dragging. */
  @Input() rotatable = false;
  /** Whether to show a toolbar allowing the user to interact with the model. */
  @Input() toolbar = false;
  /** Fired when the model data url changed by user via file drop. */
  @Output() modelDataUriChange = new EventEmitter<string>();
  /** Emits the new model name, whenever the model changes. */
  @Output() modelNameChange = new EventEmitter<string | undefined>();
  /** Emits the new model base64 preview-image whenever it changes. */
  @Output() previewImageChange = new EventEmitter<string>();
  /** Fired whenever the user clicks the model. Does not fire for model-dragging. */
  @Output() modelClick = new EventEmitter<void>();

  @HostListener('dragenter')
  @HostListener('dragover')
  moveDropZoneToFront(): void {
    this.isModelViewerAtFront = false;
  }

  moveModelViewerToFront(): void {
    this.isModelViewerAtFront = true;
  }

  public isModelViewerAtFront = false;

  ngOnInit() {
    const hasModelToView = this.modelDataUri != null;
    this.isModelViewerAtFront = hasModelToView;
  }

  constructor(private cadModel: CadModelService) {}

  async onStepFileDrop(file: File): Promise<void> {
    const isStepFile = hasFileType('.step,.stp', file);
    if (!isStepFile) {
      console.warn('Please only drop ".step" or ".stp" files');
      return;
    }

    this.moveModelViewerToFront();

    try {
      const model = await this.cadModel.convertStepToStl(file).toPromise();
      this.modelDataUri = model?.dataUrl;
      this.modelDataUriChange.emit(this.modelDataUri);
      this.modelNameChange.emit(model?.name);
    } catch (err) {
      console.error(err);
    }
  }
}
