import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadUrl',
})
export class CadUrlPipe implements PipeTransform {
  transform(cadName: null): null;
  transform(cadName: undefined): undefined;
  transform(cadName: string): string;
  transform(cadName: Maybe<string>): Maybe<string>;
  transform(cadName: Maybe<string> | null): Maybe<string> | null {
    if (cadName === null || cadName === undefined) {
      return cadName;
    }

    let cadPath = cadName;

    if (!cadName.startsWith('http')) {
      cadPath = `./images/CAD/${cadName}`;
    }

    return cadPath;
  }
}
