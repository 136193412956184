import { Subject } from 'rxjs';
import {
  BufferGeometry,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  Raycaster as RayCaster,
  SphereGeometry,
  Vector2,
  Vector3,
} from 'three';
import { CadEditorTool, CadEditorToolEvent, CadEditorToolOpts } from '../../types';
import { getPoint, mapPointToScene } from '../../utility/model.helper';
import { CadToolBase } from '../tool.base';

export class MeasureCadEditorTool extends CadToolBase implements CadEditorTool<number | null> {
  public readonly events = new Subject<CadEditorToolEvent<number | null>>();
  public readonly name = 'measure';
  // TODO: langju: maybe use real icon later
  public readonly icon = 'M';

  private rayCaster = new RayCaster();
  private mouseStart?: Vector3;
  private positionMarker: Mesh;
  private measurementLine?: Line<BufferGeometry, LineBasicMaterial>;

  protected activateTool(): void {
    if (this.renderer.domElement == null) {
      throw new Error(`[MeasureCadEditorTool#activate] renderer.domElement must be defined.`);
    }

    this.canvasRef.addEventListener('mousedown', this.setStart);
    this.canvasRef.addEventListener('mousemove', this.onMouseMove);
    this.canvasRef.addEventListener('mouseup', this.drawLineEvent);

    this.measurementLine = this.createMeasurementLine();
    this.positionMarker = this.createPositionMarker();

    this.scene.add(this.positionMarker, this.measurementLine);
  }

  protected deactivateTool(): void {
    this.canvasRef.removeEventListener('mousedown', this.setStart);
    this.canvasRef.removeEventListener('mousemove', this.onMouseMove);
    this.canvasRef.removeEventListener('mouseup', this.drawLineEvent);

    if (this.measurementLine) {
      this.scene.remove(this.measurementLine);
    }

    this.scene.remove(this.positionMarker);
    this.emitMeasureEvent('reset');
  }

  protected renderTool(): Partial<CSSStyleDeclaration> {
    return { cursor: 'crosshair' };
  }

  protected applyConfig(config: CadEditorToolOpts): void {}

  private setStart = (e: MouseEvent) => {
    const start = this.getMouseCoords(e.offsetX, e.offsetY);
    const sceneStartPoint =
      this.getPointInScene(start) ?? this.get3dPoint(this.positionMarker.position);

    this.mouseStart = sceneStartPoint;
  };

  private onMouseMove = (e: MouseEvent) => {
    const canvas = e.target as HTMLCanvasElement;
    const point = this.getPoint(e.offsetX, e.offsetY, canvas.offsetWidth, canvas.offsetHeight);
    const point3d = this.getPointInScene(point);

    if (point3d) {
      this.positionMarker.position.set(point3d.x, point3d.y, point3d.z);
    }
  };

  private drawLineEvent = (e: MouseEvent) => {
    const endPoint = this.getMouseCoords(e.offsetX, e.offsetY);
    const sceneStartPoint = this.mouseStart;
    const sceneEndPoint =
      this.getPointInScene(endPoint) ?? this.get3dPoint(this.positionMarker.position);

    if (sceneStartPoint && sceneEndPoint) {
      this.drawLine3D(sceneStartPoint, sceneEndPoint);
    }

    this.mouseStart = undefined;
    this.emitMeasureEvent('measure');
  };

  private emitMeasureEvent(type: 'measure' | 'reset') {
    const event =
      type === 'measure'
        ? { name: this.name + ':measure', payload: this.getLineLength() }
        : { name: this.name + ':reset', payload: null };

    this.events.next(event);
  }

  private createPositionMarker() {
    const geometry = new SphereGeometry(1, 32, 16);
    const material = new MeshBasicMaterial({ color: 0x2255ff, opacity: 0.2 });
    return new Mesh(geometry, material);
  }

  private createMeasurementLine() {
    const material = new LineBasicMaterial({ color: 0xfff000 });
    const geometry = new BufferGeometry().setFromPoints([new Vector3(), new Vector3()]);
    return new Line(geometry, material);
  }

  private getLineLength() {
    return this.measurementLine?.computeLineDistances().geometry.attributes.lineDistance.array[1];
  }

  private getPoint(
    offsetX: number,
    offsetY: number,
    innerWidth: number,
    innerHeight: number,
  ): Vector2 {
    const x = (offsetX / innerWidth) * 2 - 1;
    const y = -(offsetY / innerHeight) * 2 + 1;

    return new Vector2(x, y);
  }

  private get3dPoint(input: Vector3): Vector3 {
    return new Vector3(input.x, input.y, input.z);
  }

  private drawLine3D(from: Vector3, to: Vector3): void {
    if (this.measurementLine == null) {
      return;
    }

    this.measurementLine.geometry = this.measurementLine.geometry.setFromPoints([from, to]);
  }

  private getMouseCoords(offsetX: number, offsetY: number): Vector2 {
    return getPoint(offsetX, offsetY, this.canvasRef.offsetWidth, this.canvasRef.offsetHeight);
  }

  private getPointInScene(point: Vector2) {
    return mapPointToScene(point, this.rayCaster, this.camera, this.scene, [this.positionMarker]);
  }
}
