import { Injectable } from '@angular/core';
import { UsageTrackingSendImplementationService } from '@trumpf-xguide/xguide';
import { UsageTrackingMessageType, UsageTrackingTypes } from '../../../../../../../shared';

@Injectable({
  providedIn: 'root',
})
export class UsageTrackingServiceMock implements UsageTrackingSendImplementationService {
  public send(
    messageType: UsageTrackingMessageType,
    usageTracking: UsageTrackingTypes,
    anonymousGuid: string,
  ): void {
    // hint: this method will not be called by xguide UsageTrackingService if not allowed.
    usageTracking.anonymousGuid = anonymousGuid;
    console.log(`[Simulate UsageTracking] MessageType: ${messageType}`, usageTracking);
  }
}
