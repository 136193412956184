type FilterFn<T> = (currentItem: T, index: number, array: T[]) => boolean;

export function onlyUnique<T>(basedOnProperty: keyof T): FilterFn<T>;
export function onlyUnique<T>(basedOnProperty: T, index: number, array: T[]): FilterFn<T>;
export function onlyUnique<T>(
  currentItemOrProperty: T | keyof T,
  index?: number,
  array?: T[],
): boolean | FilterFn<T> {
  if (index !== undefined && array !== undefined) {
    return isUnique(currentItemOrProperty, index, array);
  }

  return (item: any, arrayIndex: number, arrayInstance: any[]) =>
    isUnique(
      item[currentItemOrProperty],
      arrayIndex,
      arrayInstance.map((i) => i[currentItemOrProperty]),
    );
}

function isUnique<T>(item: T, index: number, array: T[]): boolean {
  return array.findIndex((currentItem) => currentItem === item) === index;
}
