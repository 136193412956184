import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Part } from '../../../../../../shared';

@Component({
  selector: 'lsb-part-list-item',
  templateUrl: './part-list-item.component.html',
  styleUrls: ['./part-list-item.component.scss'],
})
export class PartListItemComponent {
  @Input() part: Part;
  @Input() hideKeywords = false;

  @Output() select = new EventEmitter<string>();

  @Output() keywordSelect = new EventEmitter<string>();

  public partSelected(partId: string) {
    this.select.emit(partId);
  }
}
