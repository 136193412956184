<ng-container [ngTemplateOutlet]="valueField ? valueTmpl : normalTmpl"></ng-container>

<ng-template #valueTmpl>
  <!-- ATOM: Input Field Value -->
  <div class="input-field-value">
    <div *ngIf="label" class="head horizontal-layout">
      <label [for]="inputId" class="label">{{ label }}</label>
      <ng-content select="[header-slot]"></ng-content>
    </div>
    <div class="content">
      <div class="input-field" [ngClass]="classes">
        <div class="input-container" [class.no-unit]="unit === undefined">
          <input
            #txt
            class="input"
            [id]="inputId"
            [value]="text"
            [attr.disabled]="disabled ? true : null"
            [attr.placeholder]="placeholder"
            [attr.readOnly]="readonly ? true : null"
            (change)="onChange(txt.value)"
          />

          <div *ngIf="unit" class="separator">{{ unit }}</div>
        </div>
        <span *ngIf="hint" class="helper-text">{{ hint }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #normalTmpl>
  <!-- ATOM: Input Field -->
  <div class="input-field" [ngClass]="classes">
    <label [for]="inputId" class="title">{{ label }}</label>
    <div class="input-container align-top">
      <input
        #txt
        [id]="inputId"
        [value]="text"
        [attr.disabled]="disabled ? true : null"
        [attr.placeholder]="placeholder"
        [attr.readOnly]="readonly ? true : null"
        (change)="onChange(txt.value)"
      />
    </div>
    <span *ngIf="hint" class="helper-text">{{ hint }}</span>
  </div>
</ng-template>
