import { Scene } from 'three';
// @ts-ignore
import { STLExporter as Exporter } from 'three/examples/jsm/exporters/STLExporter';

export class STLExporter {
  static export(scene: Scene, name: string) {
    scene.traverse(function (child) {
      child.updateMatrix();
      child.applyMatrix4(child.matrix);
    });
    console.log(scene.toJSON());

    const exporter = new Exporter();
    const stlString = exporter.parse(scene);

    this.download(new Blob([stlString]), name);
  }

  private static download(blob: Blob, name: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
