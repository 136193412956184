<ng-container *ngIf="part">
  <img
    class="image interactive-element non-draggable"
    [src]="part.image | imageUrl"
    (click)="partSelected(part.id)"
  />
  <div class="part-description">
    <lsb-part-specification [part]="part" (select)="partSelected($event)"></lsb-part-specification>
    <div class="part-keywords" *ngIf="!hideKeywords && part.keywords && part.keywords.length > 0">
      <xui-tag
        *ngFor="let keyword of part.keywords"
        class="tag"
        (click)="keywordSelect.emit(keyword.id)"
      >
        {{ keyword.displayText }}
      </xui-tag>
    </div>
  </div>
</ng-container>
