import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsAccessor, SettingsService } from '@trumpf-xguide/xguide';
import { Subject, Subscription } from 'rxjs';
import { Language } from '../../../../../../shared';
import { UserProfile } from '../../../types';
import { LanguageSettings } from '../../../types/language.settings';
import { SHARED_SETTINGS_NAMESPACE } from '../../constants';
import { NavigatorToken } from '../../injection-tokens/navigator.injection-token';
import { AuthService } from '../auth/auth.service';
import { HmiService } from '../hmi/hmi.service';

/**
 * Service that manages the currently displayed language in the frontend app.
 */
@Injectable()
export class LanguageService implements OnDestroy {
  private subscription: Subscription;
  private selectedLanguage: Language = Language.DE_DE;
  private settings: SettingsAccessor<LanguageSettings>;

  private languageSubject = new Subject<Language>();
  public currentLanguage$ = this.languageSubject.asObservable();

  public get currentLanguage() {
    return this.selectedLanguage;
  }

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private settingsService: SettingsService,
    @Inject(NavigatorToken) private browser: typeof navigator,
    private hmiService: HmiService,
  ) {
    this.settings = this.settingsService.access<LanguageSettings>(SHARED_SETTINGS_NAMESPACE, {
      language: undefined,
    });
  }

  /**
   * Finds and applies the correct language for the currently logged in user.
   *
   * ---
   *
   * Note: The `LanguageService` uses the `AuthService` to get the current user's
   * profile object. So please make sure that the `AuthService` is initialized as
   * well.
   */
  public initLanguage() {
    this.translateService.addLangs([Language.DE_DE, Language.EN_US]);
    this.translateService.setDefaultLang(Language.DE_DE);

    this.subscription = this.authService.userProfile$.subscribe((user) =>
      this.updateLanguage(user),
    );

    this.updateLanguage();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public switchLanguage(language: string): void {
    const newLanguage = this.tryGetKnownLanguage(language);

    if (newLanguage) {
      this.selectedLanguage = newLanguage;
      this.translateService.use(this.selectedLanguage);
      this.settings.update({ language: newLanguage });
      this.languageSubject.next(newLanguage);
    }
  }

  private updateLanguage(user?: UserProfile) {
    const language = this.retrieveLanguage(user);
    this.switchLanguage(language);
  }

  private retrieveLanguage(user?: UserProfile): Language {
    let language: Maybe<Language> = this.tryGetLangFromAppSettings();

    if (!language) {
      language = this.tryGetLangFromTrumpfUser(user);
    }

    if (!language) {
      language = this.tryGetLangFromBrowser();
    }

    if (!language) {
      if (this.hmiService.hideLogin) {
        // In HMI mode fallback language should be English
        language = Language.EN_US;
      } else {
        language = Language.DE_DE;
      }
    }

    return language;
  }

  private tryGetLangFromAppSettings(): Maybe<Language> {
    // In HMI mode language is set from other local storage key
    let languagePreference = this.hmiService.language;
    if (!languagePreference) {
      // if HMI key is not present, getting key from user settings
      languagePreference = this.settings.get('language');
    }

    if (languagePreference) {
      return this.tryGetKnownLanguage(languagePreference);
    }
  }

  private tryGetLangFromTrumpfUser(user?: UserProfile): Maybe<Language> {
    if (user?.locality) {
      return this.tryGetKnownLanguage(user.locality);
    }
  }

  private tryGetLangFromBrowser(): Maybe<Language> {
    const browserLanguage = this.browser.language || (this.browser as any).userLanguage;

    if (browserLanguage) {
      return this.tryGetKnownLanguage(browserLanguage);
    }
  }

  private tryGetKnownLanguage(lang: string): Language {
    const languageEnumValue = this.toLanguageEnumValue(lang);
    const language = (Language as any)[languageEnumValue];

    return language;
  }

  /**
   * Try to convert a language-string from e.g. `"en-US"` to `"EN_US"` to match the `Language`-enum keys.
   * @param lang The language string to convert into enum value.
   */
  private toLanguageEnumValue(lang: string) {
    const uppercased = lang.toUpperCase();
    const enumValue = uppercased.replace('-', '_');

    return enumValue;
  }
}
