import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FileDropModule } from '../file-drop/file-drop.module';
import { CadModelDropZoneComponent } from './components/cad-model-drop-zone/cad-model-drop-zone.component';
import { CadModelViewerComponent } from './components/cad-model-viewer/cad-model-viewer.component';
import { ThreeJsService } from './services/three-js.service';
import { ThreeJsViewerFactory } from './tokens';

const DECLARATIONS = [CadModelViewerComponent, CadModelDropZoneComponent];

@NgModule({
  imports: [CommonModule, FileDropModule, HttpClientModule],
  declarations: [DECLARATIONS],
  providers: [{ provide: ThreeJsViewerFactory, useValue: () => new ThreeJsService() }],
  exports: [DECLARATIONS],
})
export class CadViewerModule {}
