export interface PartConfigurationDetails {
  availableForCalculation: boolean;
  amountSeams: number;
  totalSeamLengthInMillimeters: number;
  totalPieces: number;
  batchSize: number;
  fixtureCostsInEur_laser: number;
  programmingTimeInSec_laser: number;
  setupTimePerBatchInSec_laser: number;
  tackingTimeInSec_laser: number;
  loadingTimeInSec_laser: number;
  measureTimeInSec_laser: number;
  weldingSpeedInMillimetersPerSec_laser: number;
  reworkTimeInSec_laser: number;
  robotWelding: boolean;
  fixtureCostsInEur_arc: number;
  programmingTimeInSec_arc: number;
  setupTimePerBatchInSec_arc: number;
  tackingTimeInSec_arc: number;
  loadingTimeInSec_arc: number;
  measureTimeInSec_arc: number;
  weldingSpeedInMillimetersPerSec_arc: number;
  reworkTimeInSec_arc: number;
}
