const BOLD_REGEX = /\*(.*?)\*/g;
const BOLD_HTML_MARKUP = '<b>$1</b>';

const LINE_BREAK_SYMBOL = '\n';
const LINE_BREAK_REGEX: RegExp = new RegExp(LINE_BREAK_SYMBOL, 'g');
const LINE_BREAK_HTML_MARKUP = '<br>';

const FORMATTERS = [formatBold, formatNewLines];

export function formatText(text: Maybe<string>): string {
  let result = sanitize(text);

  FORMATTERS.forEach((formatter) => {
    result = formatter(result);
  });

  return result;
}

function formatBold(text: string): string {
  // https://regex101.com/r/pj32mY/1
  return text.replace(BOLD_REGEX, BOLD_HTML_MARKUP);
}

function formatNewLines(text: string): string {
  // https://regex101.com/r/pj32mY/2
  return text.replace(LINE_BREAK_REGEX, LINE_BREAK_HTML_MARKUP);
}

function sanitize(text: any): string {
  const definedText = text ?? '';

  if (typeof definedText !== 'string') {
    throw new Error(
      `formatText: The text to format must be of type "string" but is of type "${typeof definedText}".`,
    );
  }

  return definedText;
}
