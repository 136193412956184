import { Camera, Raycaster, Scene, Vector2, Vector3 } from 'three';

export function getPoint(
  offsetX: number,
  offsetY: number,
  canvasWidth: number,
  canvasHeight: number,
): Vector2 {
  const x = (offsetX / canvasWidth) * 2 - 1;
  const y = -(offsetY / canvasHeight) * 2 + 1;

  return new Vector2(x, y);
}

export function mapPointToScene(
  point: Vector2,
  rayCaster: Raycaster,
  camera: Camera,
  scene: Scene,
  ignored3DObjects: any[],
): Vector3 | undefined {
  // update the picking ray with the camera and mouse position
  rayCaster.setFromCamera(point, camera);

  // calculate objects intersecting the picking ray
  const relevantObjects = scene.children.filter((child) => !ignored3DObjects.includes(child));
  const intersects = rayCaster.intersectObjects(relevantObjects);

  return intersects.map((i) => i.point)[0];
}
