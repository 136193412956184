import { Component, EventEmitter } from '@angular/core';
import { DialogHostedView, DialogResult, DialogReturnType } from '@trumpf-xguide/xguide';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';

@Component({
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
})
export class UnsupportedBrowserComponent implements DialogHostedView<any, any> {
  public readonly uiElementIds = UiElementIds;
  closeDialog = new EventEmitter<DialogReturnType<any>>();
  initDialog(_: any): void {}

  constructor(public translations: TranslationHelper) {}

  close() {
    this.closeDialog.emit({ result: DialogResult.Ok });
  }
}
