import { CabinInfo } from './cabin-info';
import { ComponentUsageState } from './component-usage-state';

/**
 * The configurator's layout-model interface as used in front- and backend.
 * This interface is similiar but not necessarily the same as the configurator's
 * layout-model interface used in the database.
 */
export interface ConfiguratorLayoutModel {
  name: string;
  cabin: CabinInfo;
  lin: ComponentUsageState;
  high: ComponentUsageState;
  dkp: ComponentUsageState;
  dt: ComponentUsageState;
  rw: ComponentUsageState;
  wp: ComponentUsageState;
  npss: ComponentUsageState;
  imgUrl: string;
  cadUrl: string;
}
