import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { RadioGroupToken } from '../../../directives/radio-group/radio-group.injection-token';
import { RadioGroup } from '../../../directives/radio-group/types';

/**
 * RadioButton that allows the user to make a unique choice out of multiple options.
 *
 * ---
 * **Please note:** This component is designed to be used within a `RadioGroupComponent`
 * or with `RadioGroupDirective` attached.
 *
 * ---
 * **Examples**
 *
 * **RadioGroupComponent (prefer):**
 *
 * ~~~html
 * <lsb-radio-group name="group-1" (selectionChange)="selectedValueIs($event)">
 *    <lsb-radio-button value="A" label="Option A"></lsb-radio-button>
 *    <lsb-radio-button value="B" label="Option B"></lsb-radio-button>
 * </lsb-radio-group>
 * ~~~
 *
 * **RadioGroupDirective (only use if necessary):**
 *
 * ~~~html
 * <table>
 * <tr>
 *  <td>
 *    <lsb-radio-button lsbRadioGroup="group1" value="A" label="A"></lsb-radio-button>
 *  </td>
 *  <td>
 *    <lsb-radio-button lsbRadioGroup="group1" value="B" label="B"></lsb-radio-button>
 *  </td>
 * </tr>
 * </table>
 * ~~~
 */
@Component({
  selector: 'lsb-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit {
  @Input() value: any;
  @Input() label: string;
  @Input() iconName: string;
  @Input() name: string;
  @Input() disabled = false;
  @Input() checked: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  private readonly id = uuid();

  constructor(@Optional() @Inject(RadioGroupToken) private radioGroup?: RadioGroup) {}

  ngOnInit() {
    this.name = this.radioGroup?.name ?? '';
    this.radioGroup?.register(this.id, (newState: boolean) => {
      this.checked = newState;
      this.checkedChange.emit(newState);
    });
  }

  public onClick() {
    this.radioGroup?.select(this.id, this.value);
  }
}
