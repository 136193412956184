import { Subject } from 'rxjs';
import { Camera, Renderer, Scene } from 'three';
import { CadEditorTool, CadEditorToolEvent, CadEditorToolOpts } from '../types';
import { setStyle } from '../utility/set-style';

/**
 * Base class for tools that are supposed to work with the `ThreeJsService`.
 */
export abstract class CadToolBase implements CadEditorTool<any> {
  /** The event bus for this tool. Events can be sent to components consuming the `ThreeJsService`. */
  public readonly events = new Subject<CadEditorToolEvent<any>>();
  public abstract readonly name: string;
  public abstract readonly icon: string;

  /** The renderer. Can be used to trigger render updates. */
  protected renderer: Readonly<Renderer>;
  /** The current camera capturing the scene. */
  protected camera: Readonly<Camera>;
  /** The scene being rendered. */
  protected scene: Readonly<Scene>;
  /** Whether the tool is currently activated. */
  protected activated = false;
  private currentConfiguration?: CadEditorToolOpts;

  /** Gets the canvas html-element being used to render the scene. */
  protected get canvasRef() {
    return this.renderer?.domElement as HTMLCanvasElement;
  }

  config(options: CadEditorToolOpts): void {
    this.currentConfiguration = options;

    if (!this.activated) {
      return;
    }

    this.applyConfig(options);
  }

  activate(renderer: Renderer, camera: Camera, scene: Scene): void {
    this.renderer = renderer;
    this.camera = camera;
    this.scene = scene;

    this.activateTool();
    this.activated = true;

    if (this.currentConfiguration != null) {
      this.config(this.currentConfiguration);
    }
  }

  deactivate(): void {
    this.deactivateTool();
  }

  render(): void {
    const cssDeclaration = this.renderTool();

    if (cssDeclaration) {
      setStyle(cssDeclaration, this.renderer.domElement);
    }
  }

  /**
   * Called when the tool got activated. Use this to register your events to the
   * `renderer.domElement` and to add potential objects to the scene.
   */
  protected abstract activateTool(): void;
  /**
   * Called when the tool got deactivated. Use this to deregister your events to the
   * `renderer.domElement` and to remove potential objects from the scene.
   */
  protected abstract deactivateTool(): void;
  /**
   * Called whenever the canvas renders. Use this to add custom styles to the canvas,
   * such as the current cursor type by returning a css style definition object.
   *
   * **Example**
   * ~~~ts
   * renderTool(): Partial<CSSStyleDeclaration> {
   *   return { cursor: this.mouseDown ? 'grabbing' : 'grab' };
   * }
   * ~~~
   */
  protected abstract renderTool(): Partial<CSSStyleDeclaration> | void;
  /**
   * Called whenever a configuration property changed.
   * @param config The config object containing all configuration properties of all tools. Only use the subset of properties that concerns your tool.
   */
  protected abstract applyConfig(config: CadEditorToolOpts): void;
}
