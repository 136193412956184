<lsb-dropdown
  class="dropdown"
  [class.active]="active"
  [label]="label"
  [disabled]="disabled"
  (lsbClickOutside)="active = false"
  (pointerdown)="active = true"
  (pointerup)="active = false"
>
  <ng-template #toggleTemplate>
    <lsb-input-field
      class="dropdown-toggle"
      [disabled]="disabled"
      [valueField]="valueField"
      [(text)]="text"
      [unit]="unit"
      (textChange)="onTextChange($event)"
    ></lsb-input-field>
  </ng-template>

  <ng-content></ng-content>
</lsb-dropdown>
