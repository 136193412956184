import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Article } from '../../../../../../shared';
import { TranslationHelper } from '../../../shared/helpers';
import { NewsService } from '../../services/news/news.service';

@Component({
  selector: 'lsb-news-tile',
  templateUrl: './news-tile.component.html',
  styleUrls: ['./news-tile.component.scss'],
})
export class NewsTileComponent implements OnInit {
  public news$: Observable<Article[]>;

  constructor(private news: NewsService, public translations: TranslationHelper) {}

  ngOnInit() {
    this.news$ = this.news.getArticles();
  }
}
