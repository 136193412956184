<div
  class="dropdown interactive-element"
  [class.open]="isOpen"
  [class.disabled]="disabled"
  (lsbClickOutside)="close()"
>
  <button
    class="basic-atom dropdown-toggle"
    [class.flat]="flat"
    [class.focus]="hasFocus"
    [disabled]="disabled"
    (click)="toggle()"
  >
    <ng-container
      [ngTemplateOutlet]="toggleTemplate || defaultToggleTemplate"
      [ngTemplateOutletContext]="{ $implicit: label }"
    ></ng-container>
  </button>
  <ul class="dropdown-list interactive-element" [class.open]="isOpen">
    <ng-content></ng-content>
  </ul>
</div>

<ng-template #defaultToggleTemplate let-label>
  <span class="button-label">{{ label }}</span>
</ng-template>
