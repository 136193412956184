import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const codeFlowAuthConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.oidcIssuer,

  // URL of the SPA to redirect the user to after login.
  // If the routes are switched via '#', we must append '/#/'.
  redirectUri: environment.oidcRedirectUri,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: environment.oidcClientId,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: environment.oidcDummySecret,

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: environment.oidcScope,

  // TODO KDK: Check what is the matter with the OIDC Document
  // that it does not pass the implemented strict validation.
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,

  showDebugInformation: !environment.production,
  requireHttps: environment.production,
};
