/**
 * Creates a mail-to link to the specified receivers.
 * @param receiver The receiver email address
 * @param cc The CC receiver address
 */
export function mailToLink(receiver: string, cc?: string, subject?: string): string {
  if (!receiver) {
    throw new Error(`Invalid email address given for receiver: "${receiver}"`);
  }

  validateMailAddresses(receiver, cc);

  const link = `mailto:${receiver}`;
  const linkWithCC = addParamIfDefined('cc', cc, link);

  const urlSafeSubject = subject ? encodeURIComponent(subject) : undefined;
  const linkWithSubject = addParamIfDefined('subject', urlSafeSubject, linkWithCC);

  return linkWithSubject;
}

function addParamIfDefined(name: Maybe<string>, value: Maybe<string>, link: string): string {
  if (!name || !value) {
    return link;
  }

  let updatedLink = link;

  if (updatedLink.includes('?')) {
    updatedLink += '&';
  } else {
    updatedLink += '?';
  }

  updatedLink += name + '=' + value;

  return updatedLink;
}

function validateMailAddresses(...emails: Maybe<string>[]) {
  emails.forEach((email) => {
    if (email === undefined) {
      return;
    }

    if (!email.includes('@')) {
      throw new Error(`Email address does not include a domain: ${email}`);
    }
  });
}
