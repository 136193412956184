<label
  [attr.disabled]="disabled ? true : null"
  [class.disabled]="disabled"
  (click)="onClick()"
  class="radio"
>
  <div class="container">
    <!--
        IMPORTANT

        control only works reliably when set to type "checkbox".
        Selection is handled by a service, so the type does not
        matter in terms of single-/unique selection.

        also leave input always disabled, since checked state is
        handled in component as well.
    -->
    <input
      disabled
      type="checkbox"
      [class.disabled]="disabled"
      [attr.name]="name"
      [attr.value]="value"
      [attr.checked]="checked ? true : null"
    />
    <span [attr.disabled]="disabled ? true : null" class="checkmark basic-atom"></span>
  </div>
  <span
    *ngIf="iconName"
    [attr.disabled]="disabled ? true : null"
    [attr.class]="'icon icon-' + iconName"
  ></span>
  <span [attr.disabled]="disabled ? true : null" class="label">{{ label }}</span>
</label>
