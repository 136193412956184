export const STEEL_SHEET_THICKNESSES: Map<number, number> = new Map<number, number>([
  [1, 6.9],
  [2, 6.35],
  [3, 6.1],
  [4, 5.7],
  [5, 5.3],
  [6, 4.9],
  [7, 4.6],
  [8, 4.2],
  [9, 3.8],
  [10, 3.4],
  [11, 3.0],
  [12, 2.7],
  [13, 2.3],
  [14, 1.9],
  [15, 1.7],
  [16, 1.5],
  [17, 1.4],
  [18, 1.2],
  [19, 1.1],
  [20, 0.9],
  [21, 0.8],
  [22, 0.8],
  [23, 0.7],
  [24, 0.6],
  [25, 0.53],
  [26, 0.46],
]);

export const STAINLESS_STEEL_SHEET_THICKNESSES: Map<number, number> = new Map<number, number>([
  [1, 7.1],
  [2, 6.7],
  [3, 6.35],
  [4, 6.0],
  [5, 5.6],
  [6, 5.2],
  [7, 4.8],
  [8, 4.4],
  [9, 4.0],
  [10, 3.6],
  [11, 3.2],
  [12, 2.8],
  [13, 2.4],
  [14, 2.0],
  [15, 1.8],
  [16, 1.6],
  [17, 1.4],
  [18, 1.3],
  [19, 1.1],
  [20, 1.0],
  [21, 0.9],
  [22, 0.8],
  [23, 0.7],
  [24, 0.6],
  [25, 0.56],
  [26, 0.48],
]);

export const ALUMINUM_SHEET_THICKNESSES: Map<number, number> = new Map<number, number>([
  [1, 7.3],
  [2, 6.5],
  [3, 5.8],
  [4, 5.2],
  [5, 4.6],
  [6, 4.1],
  [7, 3.7],
  [8, 3.3],
  [9, 2.9],
  [10, 2.6],
  [11, 2.3],
  [12, 2.1],
  [13, 1.8],
  [14, 1.6],
  [15, 1.5],
  [16, 1.3],
  [17, 1.2],
  [18, 1.0],
  [19, 0.9],
  [20, 0.8],
  [21, 0.7],
  [22, 0.6],
  [23, 0.6],
  [24, 0.5],
  [25, 0.46],
  [26, 0.4],
]);
