import { Component, Input } from '@angular/core';
import { HeadlineAndText } from '../../../../../../../shared';
import { formatText } from '../../../helpers/text-formatting-helper';

/**
 * The headline-and-text component receives a {@param value} of type `HeadlineAndText`.
 * It can also format line breaks to display text in list style.
 *
 * ```html
 * <lsb-headline-and-text [value]="headlineAndText"></lsb-headline-and-text>
 * ```
 */
@Component({
  selector: 'lsb-headline-and-text',
  templateUrl: './headline-and-text.component.html',
  styleUrls: ['./headline-and-text.component.scss'],
})
export class HeadlineAndTextComponent {
  @Input() value: HeadlineAndText | undefined;

  public get formattedValue() {
    return formatText(this.value?.text);
  }
}
