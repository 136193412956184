import { Pipe, PipeTransform } from '@angular/core';
import { SettingsAccessor, SettingsService } from '@trumpf-xguide/xguide';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../constants';
import { AppSettings, Currency } from '../types';

@Pipe({
  name: 'currencyUnit',
  pure: false,
})
export class CurrencyUnitPipe implements PipeTransform {
  private settings: SettingsAccessor<AppSettings>;

  constructor(settingsService: SettingsService) {
    this.settings = settingsService.access(SHARED_SETTINGS_NAMESPACE, DEFAULT_APP_SETTINGS);
  }

  transform(value?: string): string {
    if (!value) {
      return '';
    }

    return this.settings.get('currency') === Currency.USDollar ? value.replace('€', '$') : value;
  }
}
