import { Component, Input } from '@angular/core';

@Component({
  selector: 'lsb-number-list-item',
  templateUrl: './number-list-item.component.html',
  styleUrls: ['./number-list-item.component.scss'],
})
export class NumberListItemComponent {
  @Input() value: number;
  @Input() unit?: string;
  @Input() translationKey: string;
}
