import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  // hint: langju: DragEvent is not known by jest and causes trouble, thus we provide an own type
  DragEvent,
} from '../../types';
import { hasFileType } from '../../utility/has-file-type';

@Component({
  selector: 'lsb-file-drop-zone',
  templateUrl: './file-drop-zone.component.html',
  styleUrls: ['./file-drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropZoneComponent {
  /**
   * Defines the file types this file-drop-zone should accept.
   *
   * ---
   * [MDN docs of accept attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept)
   */
  @Input() accept?: string;
  @Input() multiple?: boolean | string = false;
  @Input() clickable?: boolean | string = false;

  /**
   * Fired whenever one or multiple files were dropped. When `multiple` is `true` the event will emit a `FileList`.
   * If it is set to `false`, the event payload will be of type `File`.
   */
  @Output() fileDrop = new EventEmitter<FileList | File>();

  @ViewChild('fileInput') fileInputRef?: ElementRef<HTMLInputElement>;

  public get allowMultiple(): true | null {
    // hint: consider empty string as true. This is the case when someone uses the multiple-flag like this:
    // <lsb-file-drop-zone multiple></lsb-file-drop-zone>
    return this.multiple !== false ? true : null;
  }

  private get fileInput(): HTMLInputElement | undefined {
    return this.fileInputRef?.nativeElement;
  }

  public acceptString: string = '*';

  @HostBinding('class.highlight')
  public highlightBorder = false;

  @HostBinding('class.show-dialog-on-click')
  public get showDialogOnClick(): boolean {
    // hint: consider empty string as true. This is the case when someone uses the multiple-flag like this:
    // <lsb-file-drop-zone clickable></lsb-file-drop-zone>
    return this.clickable !== false;
  }

  @HostListener('mouseover')
  onMouseOver(): void {
    if (!this.showDialogOnClick) {
      return;
    }

    this.highlightBorder = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.highlightBorder = false;
  }

  @HostListener('click')
  onClick(): void {
    if (!this.showDialogOnClick) {
      return;
    }

    this.showFileDialog();
    this.highlightBorder = false;
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: Event): void {
    event.preventDefault();
    this.highlightBorder = true;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: Event): void {
    event.preventDefault();
    this.highlightBorder = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: Event): void {
    event.preventDefault();
    this.highlightBorder = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.highlightBorder = false;

    if (event.dataTransfer == null) {
      return;
    }

    this.emitFileChange(event.dataTransfer.files);
  }

  public onFileChange(event: any): void {
    this.emitFileChange(event.target.files);
  }

  /**
   * Opens the file dialog and lets the user choose some file(s):
   *
   * ~~~html
   * <lsb-file-drop-zone #dropZone>
   *    <div (click)="dropZone.showFileDialog()">
   *      Click me to open the dialog!
   *    </div>
   * </lsb-file-drop-zone>
   * ~~~
   */
  public showFileDialog(): void {
    this.fileInput?.click();
  }

  private emitFileChange(files: FileList): void {
    const allowed = Array.from(files).every((file) => hasFileType(this.accept, file));
    if (!allowed) {
      return;
    }

    const payload = this.allowMultiple ? files : files[0];
    this.fileDrop.emit(payload);
  }
}
