<!-- 
    hint: langju: Important! use trackBy function to ensure that new array instances with
    old item references will NOT trigger a rerender and lead into an infinite loop!

    Example that would break without trackBy:

    <lsb-caption-list [items]="allItems.filter(...)"></lsb-caption-list>
 -->
<lsb-caption-label
  *ngFor="let item of items; trackBy: trackByCaption"
  [item]="item"
></lsb-caption-label>
