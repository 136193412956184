import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileReaderToken } from '../../tokens';
import { DataUri } from '../../types';
import { FileReaderServiceInterface } from './file-reader-service.interface';

@Injectable({ providedIn: 'root' })
export class FileReaderService implements FileReaderServiceInterface {
  constructor(@Inject(FileReaderToken) private FileReaderType: typeof FileReader) {}

  public readBinary(blob: Blob): Observable<string> {
    return new Observable((subscriber) => {
      const fileReader = new this.FileReaderType();

      fileReader.onload = (e: ProgressEvent): void => {
        subscriber.next((e.target as any).result);
        subscriber.complete();
      };
      fileReader.onerror = () => {
        subscriber.error(fileReader.error);
        subscriber.complete();
      };

      fileReader.readAsBinaryString(blob);
    });
  }

  public getDataUri(blob: Blob): Observable<DataUri> {
    return new Observable((subscriber) => {
      const fileReader = new this.FileReaderType();

      fileReader.onload = (e: ProgressEvent): void => {
        subscriber.next((e.target as any).result);
        subscriber.complete();
      };
      fileReader.onerror = () => {
        subscriber.error(fileReader.error);
      };

      fileReader.readAsDataURL(blob);
    });
  }
}
