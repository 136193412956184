import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import {
  BufferGeometry,
  FrontSide,
  HemisphereLight,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { DataUri } from '../../file-drop/types';
import { MeasureCadEditorTool } from '../cad-editor-tools/measure-tool';
import { ViewCadEditorTool } from '../cad-editor-tools/view-tool';
import {
  CadEditorTool,
  CadEditorToolBase,
  CadEditorToolEvent,
  CadEditorToolOpts,
  ThreeJsApi,
} from '../types';
import { STLExporter } from '../utility/stl-exporter';

/*
      TODO: langju:
      Unfortunately we cannot test everything related to the three.js package, since 
      importing it in a jest environment leads to exceptions that we were not able to solve.
      As soon as this module starts growing, we need to find a strategy to test this service
      and its dependencies.
   */
@Injectable()
export class ThreeJsService implements ThreeJsApi {
  public toolEvents: Observable<CadEditorToolEvent>;

  private readonly availableTools = [new ViewCadEditorTool(), new MeasureCadEditorTool()];
  private readonly modelPreview = new BehaviorSubject<string | undefined>(undefined);
  private readonly defaultControlOpts: CadEditorToolOpts = {
    autoRotate: false,
    enableRotate: false,
    enableZoom: false,
    autoRotateSpeed: 1,
  };

  private camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private scene: Scene;
  private mesh: Mesh<BufferGeometry, MeshPhongMaterial>;
  private emitModelPreviewImage = true;
  private _activeTool?: CadEditorTool;

  public get defaultTool() {
    return this.getTools()[0];
  }

  public get activeToolName(): string {
    return this._activeTool?.name ?? this.defaultTool.name;
  }

  public get modelPreview$() {
    return this.modelPreview.asObservable();
  }

  public export(): void {
    return STLExporter.export(this.scene, 'test.stl');
  }

  public dispose(): void {
    this.renderer.dispose();
    this.unregisterEvents();
  }

  public configureTools(opts?: CadEditorToolOpts): void {
    const userConfig = opts || {};
    const options: CadEditorToolOpts = { ...this.defaultControlOpts, ...userConfig };

    const tools = this.getTools() as CadEditorTool[];
    tools.forEach((tool) => tool.config(options));
  }

  public setSize(width: number, height: number): void {
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  public attachToToolEvents() {
    const tools = this.getTools() as CadEditorTool[];
    const events = tools.map((tool) => tool.events);
    this.toolEvents = merge(...events);
  }

  public getTools(): CadEditorToolBase[] {
    return this.availableTools;
  }

  public activateTool(toolName: string): void {
    this._activeTool?.deactivate();

    const toolWithSpecifiedName = this.getTools().find((t) => t.name === toolName) as CadEditorTool;
    this._activeTool = toolWithSpecifiedName ?? this.defaultTool;
    this._activeTool.activate(this.renderer, this.camera, this.scene);
  }

  public deactivateTools(): void {
    this._activeTool?.deactivate();
    this._activeTool = undefined;
  }

  public attachToAsChild(host: HTMLElement): void {
    this.camera = new PerspectiveCamera(70, host.clientWidth / host.clientHeight, 1, 1000);
    this.renderer = new WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });

    this.registerEvents();
    this.attachToToolEvents();
    this.renderer.setSize(host.clientWidth, host.clientHeight);
    host.prepend(this.renderer.domElement);
    this.render();
  }

  public async loadAndAddModel(model: DataUri) {
    this.scene = new Scene();
    this.addLight(this.scene);

    const { mesh, geometry } = await this.loadStl(model);

    this.mesh = mesh;
    this.scene.add(mesh);

    this.centerGeometry(geometry, this.mesh);
    this.scaleModel(geometry, this.camera);

    // reset flag to create and emit new preview image of changed model.
    this.emitModelPreviewImage = true;
  }

  private registerEvents() {
    this.renderer.domElement.addEventListener(
      'mouseup',
      this.stopMouseUpEventBubblingWhenToolsActive,
    );
  }

  private unregisterEvents() {
    this.renderer.domElement.removeEventListener(
      'mouseup',
      this.stopMouseUpEventBubblingWhenToolsActive,
    );
  }

  private stopMouseUpEventBubblingWhenToolsActive = (event: Event) => {
    if (this._activeTool == null) {
      return;
    }

    event.stopPropagation();
  };

  private render = () => {
    requestAnimationFrame(this.render);

    if (this.scene == null) {
      return;
    }

    this._activeTool?.render?.();
    this.renderer.render(this.scene, this.camera);

    if (this.emitModelPreviewImage) {
      this.emitModelPreviewImage = false;
      this.tryGetPreviewFromCanvas(this.renderer.domElement);
    }
  };

  private addLight(scene: Scene) {
    const light = new HemisphereLight(0xffffff, 0x000000, 1);
    scene.add(light);
  }

  private scaleModel(geometry: BufferGeometry, camera: PerspectiveCamera) {
    const boundingBox = geometry.boundingBox!;
    const largestDimension = Math.max(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z);
    camera.position.z = largestDimension * 2.5;
  }

  private centerGeometry(geometry: BufferGeometry, mesh: Mesh<BufferGeometry, MeshPhongMaterial>) {
    const middle = new Vector3();
    geometry.computeBoundingBox();
    geometry.boundingBox!.getCenter(middle);

    const placeCenterMatrix = new Matrix4().makeTranslation(-middle.x, -middle.y, -middle.z);
    mesh.applyMatrix4(placeCenterMatrix);
  }

  private async loadStl(
    model: string,
  ): Promise<{ mesh: Mesh<BufferGeometry, MeshPhongMaterial>; geometry: BufferGeometry }> {
    return new Promise((resolve) => {
      new STLLoader().load(model, (geometry) => {
        const material = new MeshPhongMaterial({
          color: 0xcccccc,
          specular: 100,
          shininess: 100,
          side: FrontSide,
        });
        const mesh = new Mesh(geometry, material);
        resolve({ mesh, geometry });
      });
    });
  }

  private tryGetPreviewFromCanvas = (canvas: HTMLCanvasElement): void => {
    if (!canvas) {
      return;
    }

    const base64Image = canvas.toDataURL();
    this.modelPreview.next(base64Image);
  };
}
