import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media, MediaType } from '../../../../../../shared';
import { TranslationHelper } from '../../../shared/helpers';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  public tutorialVideo$: Observable<Media | undefined> = of(undefined);

  constructor(public translations: TranslationHelper, private translate: TranslateService) {}

  ngOnInit() {
    this.tutorialVideo$ = this.translate
      .stream(this.translations.DASHBOARD.GETTING_STARTED.TOUR_VIDEO_URL)
      .pipe(
        map((url) => ({
          type: MediaType.VIDEO,
          src: url,
        })),
      );
  }
}
